import katex from "katex";

export const USER_ID = "TEST_EDU_USER_ID";
export const USER_CODE = "TEST_EDU_CODE";
export const USER_POSITION = "TEST_EDU_USER_POSITION";
export const USERNAME = "TEST_EDU_USERNAME";
export const TOKEN = "TEST_EDU_TOKEN";
export const LANGUAGE = "TEST_EDU_LANGUAGE";
export const ROLE = "TEST_EDU_ROLE";

export const ROLE_A = "ROLE_ADMIN";
export const ROLE_U = "ROLE_USER";

export const token = localStorage.getItem(TOKEN);
export const role = localStorage.getItem(ROLE);
export const userCode = localStorage.getItem(USER_CODE);
export const userPosition = localStorage.getItem(USER_POSITION);
export const isUser = role === ROLE_U && token;
export const isAdmin = role === ROLE_A && token;
export const language = localStorage.getItem(LANGUAGE);

export const baseFormats = {
  excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const defaultFonts = [
  "Arial",
  "Comic Sans MS",
  "Courier New",
  "Impact",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];

export const sortedFontOptions = [
  "Logical",
  "Salesforce Sans",
  "Garamond",
  "Sans-Serif",
  "Serif",
  "Times New Roman",
  "Helvetica",
  ...defaultFonts,
].sort();

export const sunEditorOptions = {
  katex: katex,
  buttonList: [
    ["undo", "redo"],
    ["font", "fontSize"],
    ["paragraphStyle", "blockquote"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor"],
    ["align", "list", "lineHeight"],
    ["outdent", "indent"],

    ["table", "horizontalRule", "link", "image", "video"],
    ["math"], //You must add the 'katex' library at options to use the 'math' plugin.
    // ["imageGallery"], // You must add the "imageGalleryUrl".
    ["fullScreen", "showBlocks", "codeView"],
    ["preview", "print"],
    ["removeFormat"],

    // ['save', 'template'],
    // '/', Line break
  ], // Or Array of button list, eg. [['font', 'align'], ['image']]
  defaultTag: "div",
  minHeight: "200px",
  showPathLabel: false,
  font: sortedFontOptions,
};
