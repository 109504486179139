import {
  Form,
  Button,
  Breadcrumb,
  Checkbox,
  message,
  Select,
  DatePicker,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { adminHomeBcmb } from "src/static/data/breadcrumb_items";
import {
  QuizRuleResTypes,
  QuizRuleTypes,
  UniversityTypes,
} from "src/static/interface";
import { rules } from "src/static/rules";
import {
  CreateQuizRuleConfig,
  GetQuizRuleByIdConfig,
  UpdateQuizRuleConfig,
} from "src/service/api";
import { GetUniversities } from "src/service/api/open";
import moment from "moment";
import ThemFormItem from "src/components/form_item/theme";

function QuizCollectionActionsPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [universities, setUniversities] = useState<UniversityTypes[]>([]);
  const [allUni, setAllUni] = useState(false);
  const { Option } = Select;
  const collId = searchParams.get("collId");

  const handleSet = () => {
    GetUniversities().then((val) => {
      setUniversities(val.data);

      if (collId) {
        GetQuizRuleByIdConfig(collId).then(
          (res: { data: QuizRuleResTypes }) => {
            let arr: number[] = [];
            let { id, theme, endTime, universities, ...rest } = res.data;

            universities.forEach((item) => {
              arr.push(item.id);
            });

            form.setFieldsValue({
              ...rest,
              endTime: moment(endTime),
            });

            if (universities.length === val.data.length) {
              setAllUni(true);
            } else {
              form.setFieldsValue({ universities: arr });
            }
          }
        );
      } else {
        form.setFieldsValue({ active: true });
      }
    });
  };

  useEffect(() => {
    handleSet();
  }, []);

  const handleSubmit = (val: QuizRuleTypes) => {
    //Parse format
    let date = new Date(val.endTime);
    var fraction = 23.5;
    let time = date.setUTCSeconds(fraction * 3600) - 23.5 * 3600000;
    val.endTime = time;

    if (allUni) {
      let arr: number[] = [];
      universities.forEach((item) => {
        arr.push(item.id);
      });
      val.universities = arr;
    }

    setLoading(true);
    if (collId) {
      val.id = +collId;
      UpdateQuizRuleConfig(val)
        .then(() => {
          message.success(
            "Shakllangan so'rovnoma ma'lumotlari muvaffiyaqatli tahrirlandi"
          );
          navigate("/admin/quiz_collection");
        })
        .finally(() => setLoading(false));
    } else {
      CreateQuizRuleConfig(val)
        .then(() => {
          message.success("So'rovnomalar muvvafaqiyatli shakllantirildi");
          navigate("/admin/quiz_collection");
        })
        .finally(() => setLoading(false));
    }
  };

  const handleChecked = (all: boolean) => {
    setAllUni(all);
    form.setFieldsValue({ universities: [] });
  };

  return (
    <div>
      <Breadcrumb
        items={[
          adminHomeBcmb,
          {
            title: (
              <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                So'rovnoma shakllantirish
              </div>
            ),
          },
          {
            title: collId ? "Ma'lumotlarni tahrirlash" : "Ma'lumot qo'shish",
          },
        ]}
      />
      <div className="layout-detail">
        <div className="layout-detail-header">So'rovnoma shakllantirish</div>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox>So'rovnoma aktivligi</Checkbox>
          </Form.Item>

          <ThemFormItem />

          <Form.Item
            name="endTime"
            label="So'rovnoma tugash vaqti"
            rules={[rules.quiz_end_t]}
          >
            <DatePicker
              size="large"
              format="YYYY-MM-DD HH:mm"
              placeholder={rules.quiz_end_t.message}
              showTime
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="universities"
            label="Universitetlar"
            rules={allUni ? [] : [rules.university]}
          >
            <Select
              size="large"
              mode="multiple"
              allowClear
              placeholder={rules.university.message}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              disabled={allUni}
            >
              {universities.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item valuePropName="checked">
            <Checkbox onChange={(ev) => handleChecked(ev.target.checked)}>
              Barcha universitetlar
            </Checkbox>
          </Form.Item>

          <div className="modal-footer">
            <Button htmlType="submit" type="primary" loading={loading}>
              Saqlash
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default QuizCollectionActionsPage;
