import { Button, Form, Modal, Select, Table, Upload, message } from "antd";
import { useEffect, useState } from "react";
import {
  BlockResTypes,
  ExcelFormTypes,
  TestResTypes,
} from "src/static/interface";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { testAdminColumns } from "src/static/data/columns";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import DeleteModal from "src/components/modal/DeleteModal";
import PaginationFilter from "src/components/filter/PaginationFilter";
import SearchFilter from "src/components/filter/SearchFilter";
import { Link } from "react-router-dom";
import {
  DeleteAdminTestConfig,
  GetAdminTestsConfig,
  GetBlocksConfig,
} from "src/service/api";
import { rules } from "src/static/rules";
import { CreateTestByExcelConfig } from "src/service/api/multipart";
import BlockFilter from "src/components/filter/BlockFilter";

function TestAddPage() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [searchParams] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [id, setId] = useState(0);
  const [total, setTotal] = useState(0);
  const [render, setRender] = useState(1);
  const [dataSource, setDataSource] = useState<TestResTypes[]>([]);
  const [blocks, setBlocks] = useState<BlockResTypes[]>([]);
  const [loading, setLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);

  const handleMakeUrl = () => {
    let url = `?`;
    for (let key of Array.from(searchParams.keys())) {
      let value = searchParams.get(key);
      url = url + `&${key}=${value}`;
    }
    return url === "?" ? "" : url;
  };

  const getFunc = () => {
    setLoading(true);
    let url = handleMakeUrl();

    GetAdminTestsConfig(url)
      .then((res) => {
        const arr = res.data.content;
        let size = searchParams.get("size");
        let page = searchParams.get("page");
        arr.forEach((item: TestResTypes, index: number) => {
          item.key = page && size ? index + 1 + (+page - 1) * +size : index + 1;
        });
        setDataSource(arr);
        setTotal(res.data.totalElements);
        window.scrollTo(0, 0);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getFunc();
  }, [searchParams]);

  //Delete Data section
  const openDeleteModal = (Id: number) => {
    if (Id) {
      setId(Id);
      setRender(render + 1);
      setVisibleDelete(true);
    }
  };

  const closeDeleteModal = () => {
    setVisibleDelete(false);
    setId(0);
  };

  const handleDeleteFunc = () => {
    if (id)
      DeleteAdminTestConfig(id).then(() => {
        closeDeleteModal();
        message.success(`Test muvaffaqiyatli o'chirildi`);
        getFunc();
      });
  };

  const columns: ColumnsType<TestResTypes> = [
    ...testAdminColumns,
    {
      title: "Amallar",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (id) => (
        <div className="layout-content-table-buttons">
          <EditOutlined
            onClick={() => {
              navigate(`actions?testId=${id}`);
            }}
          />
          <DeleteOutlined
            onClick={() => {
              openDeleteModal(id);
            }}
          />
        </div>
      ),
    },
  ];

  //Add By Excel section
  const openExcelModal = () => {
    setVisible(true);
    if (blocks.length === 0)
      GetBlocksConfig("?page=1&size=10000").then((res) => {
        setBlocks(res.data.content);
      });
  };

  const closeExcelModal = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleSubmit = (val: ExcelFormTypes) => {
    let param = `blockId=${val.blockId}`;
    const obj = new FormData();
    obj.append("file", val.file.file);
    setExcelLoading(true);
    CreateTestByExcelConfig(param, obj)
      .then(() => {
        getFunc();
        message.success("Testlar muvaffaqiyatli qo'shildi");
        closeExcelModal();
      })
      .finally(() => setExcelLoading(false));
  };
  return (
    <div>
      <div className="layout-content-header">
        <p>Testlar</p>
        <div className="right">
          <SearchFilter />
          <BlockFilter />
          <Link to="actions">
            <Button icon={<PlusOutlined />} type="primary">
              Qo'shish
            </Button>
          </Link>
          <Button
            onClick={openExcelModal}
            icon={<UploadOutlined />}
            type="primary"
            className="excel"
          >
            Excel orqali qo'shish
          </Button>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        loading={loading}
      />
      <PaginationFilter total={total} />
      <div className="layout-content-total">
        Jami: <span>{total}</span>
      </div>

      {/* Excel modal */}
      <Modal
        open={visible}
        title="Excel orqali test qo'shish"
        footer=""
        width={500}
        onCancel={closeExcelModal}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="blockId" label="Blok" rules={[rules.block_s]}>
            <Select allowClear size="large" placeholder={rules.block_s.message}>
              {blocks.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="file" label="Test fayl" rules={[rules.file]}>
            <Upload
              multiple={false}
              beforeUpload={() => false}
              maxCount={1}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              <Button icon={<DownloadOutlined />}>Faylni yuklash</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <div className="modal-footer">
              <Button danger>Bekor qilish</Button>
              <Button type="primary" htmlType="submit" loading={excelLoading}>
                Saqlash
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      {/* Delete modal */}
      <Modal
        open={visibleDelete}
        title=""
        footer=""
        width={500}
        onCancel={closeDeleteModal}
      >
        <DeleteModal
          text="test"
          render={render}
          closeModal={closeDeleteModal}
          deleteFunc={handleDeleteFunc}
        />
      </Modal>
    </div>
  );
}

export default TestAddPage;
