import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const adminHomeBcmb = {
  title: (
    <Link to="/admin">
      <HomeOutlined />
    </Link>
  ),
};
