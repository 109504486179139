import { HomeSVG, RightSVG } from "src/assets/svg";
import { Outlet, useNavigate } from "react-router-dom";
import {
  profileMenuItems,
  // profileTeacherMenuItems,
} from "src/static/data/menu_items";
// import { userPosition } from "src/static/const";
// import { CheckHemisIdConfig } from "src/service/api";
// import { useEffect } from "react";
// import { notification } from "antd";
import ProfileButton from "src/components/common/button/ProfileButton";

function Profile() {
  const navigate = useNavigate();
  // const [api, contextHolder] = notification.useNotification();

  // const getFunc = () => {
  //   CheckHemisIdConfig().then((res) => {
  //     if (!res.data.status)
  //       api["warning"]({
  //         message: "Diqqat!",
  //         description: res.data.message,
  //         placement: "top",
  //         duration: 120,
  //       });
  //   });
  // };

  // useEffect(() => {
  //   getFunc();
  // }, []);

  return (
    <div className="profile">
      {/* {contextHolder} */}
      <div className="container profile__navbar">
        <h4 className="flex" onClick={() => navigate("/")}>
          <HomeSVG />
          <span>Bosh sahifa</span>
        </h4>
        <h4>
          <RightSVG />
        </h4>
        <h4 className="flex">
          <span>Profil</span>
        </h4>
      </div>
      <div className="container">
        <div className="profile__sidebar">
          <div className="profile__menu">
            { profileMenuItems
            .map((item) => {
              return (
                <ProfileButton
                  svg={item.icon}
                  title={item.title}
                  link={item.link}
                  key={crypto?.randomUUID()}
                />
              );
            })}
          </div>
        </div>
        <div className="profile__body">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Profile;
