import type { TabsProps } from "antd";

export const loginTabItems: TabsProps["items"] = [
  {
    key: "1",
    label: `Talabalar`,
  },
  {
    key: "2",
    label: `O'qituvchilar`,
  },
];
