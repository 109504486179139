import { CloseSvg } from "src/assets/svg";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { QuizSolveTypes } from "src/static/interface";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Drawer,
  Form,
  Modal,
  Progress,
  Statistic,
  message,
} from "antd";
import {
  FinishQuizConfig,
  GetUserQuizesConfig,
  SendQuizAnswerConfig,
} from "src/service/api";
import Quiz from "src/components/quiz";

function QuizesSolvePage({ location = "quiz" }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mainId = searchParams.get("id");
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<QuizSolveTypes[]>([]);
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState<number | undefined>();
  const [endTime, setEndTime] = useState("");
  const [duration, setDuration] = useState<number>(36000);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleAnswer = (quizId: number, index: number, type: string) => {
    let arr = data;
    let answerObj = arr.find((el) => el.id === quizId);
    let answers = {};
    if (answerObj) {
      if (type === "input") {
        let value = form.getFieldValue(quizId);
        if (value) {
          let map = new Map();
          map.set(index, value);
          answers = Object.fromEntries(map.entries());

          sendAnswers(quizId, answers);

          answerObj.answers = answers;
          setData(arr);
        }
      } else {
        let { variants } = answerObj;
        if (answerObj.buttonType === "radio") {
          let map = new Map();
          map.set(index, variants[index]);
          answers = Object.fromEntries(map.entries());
          sendAnswers(quizId, answers);

          answerObj.answers = answers;
          setData(arr);
        } else {
          let map = new Map<any, any>(Object.entries(answerObj.answers));
          if (map.get(String(index))) {
            if (Object.keys(answerObj.answers).length === 1)
              message.warning(
                "Siz eng kamida bitta javob belgilashingiz kerak"
              );
            else {
              map.delete(String(index));
              answers = Object.fromEntries(map.entries());
              sendAnswers(quizId, answers);

              answerObj.answers = answers;
              setData(arr);
            }
          } else {
            map.set(index, variants[index]);
            answers = Object.fromEntries(map.entries());
            sendAnswers(quizId, answers);

            answerObj.answers = answers;
            setData(arr);
          }
        }
      }
    }
  };

  const sendAnswers = (quizId: number, answers: any) => {
    let obj = {
      id: quizId,
      answers: answers,
    };
    SendQuizAnswerConfig(obj).catch((err) => {
      if (err.response.data.message === "Muddati o'tib ketgan")
        navigate("/profile/quizes");
    });
  };

  const handleFinishTest = () => {
    if (mainId) {
      let unfinished = data.find((el) => Object.keys(el.answers).length === 0);
      if (unfinished)
        message.warning("Iltimos so'rovnomani to'liq yakunklang!");
      else
        FinishQuizConfig(mainId).then(() => {
          message.success("So'rovnoma javoblaringiz muvaffaqiyatli saqlandi");
          navigate(`/profile/quizes?status=Yakunlangan`);
        });
    }
  };

  const makeAnswersCount = () => {
    let count = 0;
    data.forEach((item) => {
      if (Object.keys(item.answers).length > 0) count++;
    });
    return count;
  };

  const getFunc = async () => {
    if (mainId) {
      GetUserQuizesConfig(+mainId).then((res) => {
        let arr = res.data;
        setData(arr);

        let map = new Map();
        arr.forEach((item: QuizSolveTypes) => {
          if (item.buttonType === "input" && item.answers[-1])
            map.set(item.id, item.answers[-1]);
        });

        let answers = Object.fromEntries(map.entries());
        form.setFieldsValue(answers);

        if (arr.length > 0) {
          setEndTime(arr[0].endTime);
          setDuration((arr[0].endTime - arr[0].startTime) / 100);
        }
      });
    }
  };

  useEffect(() => {
    getFunc();
  }, []);

  const resultsContent = (
    <div className="test-table-numbers">
      {data.map((item, index) => {
        return (
          <a
            href={`#quiz${index + 1}`}
            className={`${
              Object.keys(item.answers).length > 0 && "test-table-number"
            }`}
            onClick={onClose}
            key={index + "numbers"}
          >
            {index + 1}
          </a>
        );
      })}
    </div>
  );

  const tableContent = (
    <div className="test-table-content">
      <div className="test-table-close">
        <span onClick={onClose}>
          <CloseSvg />
        </span>
      </div>
      <div className="test-table-header">
        <h2>So'rovnoma</h2>
        <div className="test-table-header-info">
          <p>
            <span>{makeAnswersCount()}</span>/{data.length}
          </p>
          <div className="test-table-header-info-right">
            <span className="time">
              {/* <span style={{ marginBottom: "-6px" }}>Vaqt:</span>{" "}
              <Statistic.Countdown
                onChange={(val) =>
                  typeof val === "number" && setTime(val / duration)
                }
                format="DD:HH:mm:ss"
                value={endTime}
              /> */}
            </span>
            {/* <Progress percent={time} size="small" showInfo={false} /> */}
          </div>
        </div>
      </div>
      {resultsContent}
      <div onClick={() => setVisible(true)} className="test-table-footer">
        Yakunlash
      </div>
    </div>
  );

  return (
    <>
      {location === "quiz" ? (
        <>
          <div className="test">
            <div className="test-content container">
              <Button
                onClick={showDrawer}
                className="test-button"
                type="primary"
                size="large"
              >
                Batafsil
              </Button>
              <div className="test-container">
                <h2>{data[0]?.theme}</h2>
                <Form form={form} layout="vertical">
                  {data.map((item, index) => (
                    <Quiz
                      data={item}
                      index={index}
                      location={location}
                      key={index + "key"}
                      handleAnswer={handleAnswer}
                    />
                  ))}
                </Form>
                <div
                  onClick={() => setVisible(true)}
                  className="test-table-footer test-container-footer"
                >
                  Yakunlash
                </div>
              </div>
            </div>
            <div className="test-table">{tableContent}</div>
            <Drawer
              className="test-drawer"
              title=""
              placement="right"
              onClose={onClose}
              open={open}
            >
              {tableContent}
            </Drawer>

            {/* Finish test modal */}
            <Modal
              title=""
              width={350}
              open={visible}
              okText="Ha"
              cancelText="Bekor qilish"
              onOk={handleFinishTest}
              onCancel={() => setVisible(false)}
              className="no-close-button"
            >
              <h2 style={{ textAlign: "center" }} className="no-data-p">
                So'rovnomani yakunlashni tasdiqlaysizmi?
              </h2>
              {resultsContent}
              <br />
            </Modal>
          </div>{" "}
        </>
      ) : (
        <>
          <Button
            icon={<ArrowLeftOutlined />}
            style={{ marginBottom: 16 }}
            onClick={() => navigate("/profile/quizes?status=Yakunlangan")}
          >
            Orqaga
          </Button>
          <div className="test profile__test">
            <div className="test-container">
              <Form form={form} layout="vertical">
                {data.map((item: any, index: number) => (
                  <Quiz
                    data={item}
                    index={index}
                    location={location}
                    key={index + "key"}
                    handleAnswer={() => {}}
                  />
                ))}
              </Form>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default QuizesSolvePage;
