import { Input } from "antd";
import { useSearchParams } from "react-router-dom";

function SearchFilter() {
  const { Search } = Input;
  const [searchParams, setSearchParams] = useSearchParams();

  const onSearch = (value: string) => {
    if (value) {
      if (searchParams.has("search")) searchParams.set("search", value);
      else searchParams.append("search", value);
    } else searchParams.delete("search");

    searchParams.delete("offset");
    setSearchParams(searchParams);
  };

  return (
    <Search
      defaultValue={searchParams.get("search") || undefined}
      placeholder="Qidiruv..."
      onSearch={onSearch}
      enterButton
      allowClear
      style={{ width: 300 }}
    />
  );
}

export default SearchFilter;
