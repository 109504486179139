import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { GetThemesConfig } from "src/service/api";
import { ThemeTypes } from "src/static/interface";
import { rules } from "src/static/rules";

function ThemFormItem() {
  const { Option } = Select;
  const [data, setData] = useState<ThemeTypes[]>([]);
  const getFunc = () => {
    GetThemesConfig().then((res) => {
      setData(res.data);
    });
  };
  useEffect(() => {
    getFunc();
  }, []);
  return (
    <Form.Item name="themeId" label="Mavzu" rules={[rules.theme_s]}>
      <Select
        allowClear
        size="large"
        placeholder={rules.theme_s.message}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option: any) =>
          option?.children?.toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {data.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default ThemFormItem;
