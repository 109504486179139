import { QuizPageProps } from "src/static/interface";
import { EmtpyRoundSvg, FullRoundSvg } from "../../assets/svg";
import { CheckOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";

function Quiz({ data, index, location, handleAnswer }: QuizPageProps) {
  const { TextArea } = Input;
  return (
    <div
      className="test-box"
      key={`testbox${index}}`}
      id={"quiz" + (index + 1)}
    >
      <div className="flex">
        <h2>{index + 1}.</h2>
        <div dangerouslySetInnerHTML={{ __html: data.quiz }} />
      </div>
      <div className="test-box-variants">
        {data.variants?.map((item, num) => {
          return (
            <a
              href={
                data.buttonType === "radio" && location === "quiz"
                  ? `#quiz${index + 1}`
                  : location === "quiz"
                  ? "#multiple"
                  : "#profile"
              }
              className={`${
                Object.keys(data.answers).includes(String(num)) &&
                "test-box-active"
              }`}
              onClick={() => handleAnswer(data.id, num, "")}
              style={location === "profile" ? { cursor: "initial" } : {}}
              key={"link" + num}
            >
              {data.buttonType === "radio" && (
                <>
                  {Object.keys(data.answers).includes(String(num)) ? (
                    <FullRoundSvg />
                  ) : (
                    <EmtpyRoundSvg />
                  )}
                </>
              )}
              {data.buttonType === "checkbox" && (
                <>
                  <div className="checkbox">
                    {Object.keys(data.answers).includes(String(num)) ? (
                      <div className="active">
                        <CheckOutlined />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </>
              )}

              <div
                dangerouslySetInnerHTML={{ __html: data.variants[num] }}
              ></div>
            </a>
          );
        })}
        {data.buttonType === "input" && (
          <Form.Item
            name={data.id}
            rules={[{ required: true, message: "Tegishli joyni to'ldiring" }]}
          >
            <TextArea
              rows={3}
              style={{ marginTop: 16 }}
              onBlur={() => handleAnswer(data.id, -1, "input")}
              disabled={location === "profile"}
            />
          </Form.Item>
        )}
      </div>
    </div>
  );
}

export default Quiz;
