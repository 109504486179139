import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Table, Upload, message, Breadcrumb, Modal } from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { CollectionUsersTypes } from "src/static/interface";
import {
  DeleteAccessUserConfig,
  GetAccessTeachersConfig,
  GetAccessUsersConfig,
  GetCollectionByIdConfig,
  GetTeacherCollectionByIdConfig,
} from "src/service/api";
import { collectionUserColumns } from "src/static/data/columns";
import {
  AddAccessTeacherConfig,
  AddAccessUserConfig,
} from "src/service/api/multipart";
import { adminHomeBcmb } from "src/static/data/breadcrumb_items";
import { generateExcel } from "src/utils/excel/generate";
import SearchFilter from "src/components/filter/SearchFilter";
import DeleteModal from "src/components/modal/DeleteModal";
import PaginationFilter from "src/components/filter/PaginationFilter";

function CollectionUsersPage({ user = "student" }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [dataSource, setDataSource] = useState<CollectionUsersTypes[]>([]);
  const [loading, setLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [theme, setTeheme] = useState("");
  const [visible, setVisible] = useState(false);
  const [render, setRender] = useState(1);
  const [userId, setUserId] = useState<number | null>();
  const [total, setTotal] = useState(0);
  const collId = searchParams.get("collId");

  //Get section
  const getFunc = () => {
    if (collId) {
      let size = searchParams.get("size");
      let page = searchParams.get("page");
      setLoading(true);
      if (!theme) {
        if (user === "teacher")
          GetTeacherCollectionByIdConfig(collId).then((res) =>
            setTeheme(res.data.theme)
          );
        else
          GetCollectionByIdConfig(collId).then((res) => {
            setTeheme(res.data.theme);
          });
      }
      if (user === "teacher") {
        let url = `?testRuleTeacherId=${collId}`;
        for (let key of Array.from(searchParams.keys())) {
          if (key !== "collId") {
            let value = searchParams.get(key);
            url = url + `&${key}=${value}`;
          }
        }
        GetAccessTeachersConfig(url)
          .then((res) => {
            const arr = res.data.content;
            arr.forEach((item: CollectionUsersTypes, index: number) => {
              item.key =
                page && size ? index + 1 + (+page - 1) * +size : index + 1;
            });
            setDataSource(arr);
            setTotal(res.data.totalElements);
          })
          .finally(() => setLoading(false));
      } else {
        let url = `?testRuleId=${collId}`;
        for (let key of Array.from(searchParams.keys())) {
          if (key !== "collId") {
            let value = searchParams.get(key);
            url = url + `&${key}=${value}`;
          }
        }
        GetAccessUsersConfig(url)
          .then((res) => {
            const arr = res.data.content;
            arr.forEach((item: CollectionUsersTypes, index: number) => {
              item.key =
                page && size ? index + 1 + (+page - 1) * +size : index + 1;
            });
            setDataSource(arr);
            setTotal(res.data.totalElements);
          })
          .finally(() => setLoading(false));
      }
    }
  };

  useEffect(() => {
    getFunc();
  }, [searchParams]);

  //Delete section
  const openModal = (id: number) => {
    if (id) {
      setUserId(id);
      setRender(render + 1);
      setVisible(true);
    }
  };

  const closeModal = () => {
    setVisible(false);
    setUserId(null);
  };

  const handleDelete = () => {
    userId &&
      DeleteAccessUserConfig(userId).then(() => {
        message.success("Foydalanuvchi muvaffaqiyatli o'chirildi");
        getFunc();
        closeModal();
      });
  };

  //Upload section
  const handleUplaod = (info: any) => {
    let formData = new FormData();
    formData.append("file", info.file);
    if (collId) {
      if (user === "teacher")
        AddAccessTeacherConfig(collId, formData).then(() => {
          message.success("Foydalanuvchilar muvaffaqiyatli biriktirildi");
          getFunc();
        });
      else
        AddAccessUserConfig(collId, formData).then(() => {
          message.success("Foydalanuvchilar muvaffaqiyatli biriktirildi");
          getFunc();
        });
    }
  };

  const props = {
    multiple: false,
    beforeUpload: () => false,
    maxCount: 1,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onChange: handleUplaod,
  };

  //Generate excel section
  const handleGenerate = () => {
    if (collId) {
      let size = searchParams.get("size");
      let page = searchParams.get("page");
      if (user === "teacher") {
        setExcelLoading(true);
        let url = `?testRuleTeacherId=${collId}&size=${total}`;
        GetAccessTeachersConfig(url)
          .then((res) => {
            const arr = res.data.content;
            arr.forEach((item: CollectionUsersTypes, index: number) => {
              item.key =
                page && size ? index + 1 + (+page - 1) * +size : index + 1;
            });
            generateExcel(arr, theme);
          })
          .finally(() => setExcelLoading(false));
      } else {
        setExcelLoading(true);
        let url = `?testRuleId=${collId}&size=${total}`;
        GetAccessUsersConfig(url)
          .then((res) => {
            const arr = res.data.content;
            arr.forEach((item: CollectionUsersTypes, index: number) => {
              item.key =
                page && size ? index + 1 + (+page - 1) * +size : index + 1;
            });
            generateExcel(arr, theme);
          })
          .finally(() => setExcelLoading(false));
      }
    }
  };

  //Table section
  const columns: ColumnsType<CollectionUsersTypes> = [
    ...collectionUserColumns,
    {
      title: "Amallar",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (id) => (
        <div className="layout-content-table-buttons">
          <div></div>
          <DeleteOutlined
            onClick={() => {
              openModal(id);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="layout-content-users">
      <Breadcrumb
        items={[
          adminHomeBcmb,
          {
            title: (
              <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                {user === "teacher"
                  ? "O'qituvchilar uchun shakllantirigan testlar"
                  : "Talabalar uchun shakllantirilgan testlar"}
              </div>
            ),
          },
          {
            title: "Biriktirilgan foydalanuvchilar",
          },
        ]}
      />
      <br />
      <div className="layout-content-header">
        <p>{theme} mavzusidagi testlar uchun biriktirilgan foydalanuvchilar</p>
      </div>
      <div className="layout-content-header">
        <Button
          onClick={handleGenerate}
          icon={<UploadOutlined />}
          type="primary"
          className="excel"
          loading={excelLoading}
        >
          Excel yuklash
        </Button>
        <div className="right">
          {/* <SearchFilter /> */}
          <Upload {...props}>
            <Button icon={<PlusOutlined />} type="primary">
              Qo'shish
            </Button>
          </Upload>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        pagination={false}
      />
      <PaginationFilter total={total} />
      <div className="layout-content-total">
        Jami: <span>{total}</span>
      </div>

      {/* Delete modal */}
      <Modal
        open={visible}
        title=""
        footer=""
        width={500}
        onCancel={closeModal}
      >
        <DeleteModal
          text="Foydalanuvchi"
          render={render}
          closeModal={closeModal}
          deleteFunc={handleDelete}
        />
      </Modal>
    </div>
  );
}

export default CollectionUsersPage;
