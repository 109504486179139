import { useEffect, useState } from "react";
import { Button } from "antd";
import { DeleteModalProps } from "src/static/interface";

import Lottie from "react-lottie";
import * as lottiejson from "src/assets/lottie/delete.json";

function DeleteModal({
  text,
  closeModal,
  deleteFunc,
  render,
}: DeleteModalProps) {
  const [stopped, setStopped] = useState(false);
  let dataLottie = {
    loop: true,
    autoplay: true,
    animationData: lottiejson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    setStopped(false);
    setTimeout(() => {
      setStopped(true);
    }, 2000);
  }, [render]);

  return (
    <div className="modal-delete">
      <Lottie
        options={dataLottie}
        height={300}
        width={300}
        isStopped={stopped}
      />
      <h3>Siz rostdan ham {text} ni o'chirib yubormoqchimisz?</h3>
      <div className="modal-delete-buttons">
        <div>
          <Button size="large" type="text" onClick={closeModal}>
            Bekor qilish
          </Button>
        </div>
        <div>
          <Button size="large" type="primary" danger onClick={deleteFunc}>
            Ha
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
