import {
  CollectionTeacherTypes,
  CollectionTypes,
  QuizRuleTypes,
  QuizSendTypes,
  QuizTypes,
  TestAdminTypes,
  ThemeTypes,
} from "src/static/interface";
import { CreateFunc, DeleteFunc, EditFunc, GetFunc } from "../config";

//////////// Admin controller ////////////
export const GetAdminStatConfig = (param: string) => {
  return GetFunc(`api/stat/adminStatik?${param}`);
};

// Qustion CRUD //
export const GetAdminTestsConfig = (param?: string) => {
  return GetFunc(`admin/getQuestionResponses${param}`);
};
export const GetAdminTestByIdConfig = (id?: string) => {
  return GetFunc(`admin/getQuestionResponseId/${id}`);
};
export const CreateAdminTestConfig = (data: TestAdminTypes) => {
  return CreateFunc(`admin/addQuestion`, data);
};
export const UpdateAdminTestConfig = (id: string, data: TestAdminTypes) => {
  return EditFunc(`admin/updateQuestion/${id}`, data);
};
export const DeleteAdminTestConfig = (id: number) => {
  return DeleteFunc(`admin/deleteQuestion/${id}`);
};

// Collection CRUD //
export const GetCollectionsConfig = (param?: string) => {
  return GetFunc(`admin/getTestsRules${param}`);
};
export const GetCollectionByIdConfig = (id?: string) => {
  return GetFunc(`admin/getTestRuleById/${id}`);
};
export const CreateCollectionConfig = (data: CollectionTypes) => {
  return CreateFunc(`admin/createRuleTest`, data);
};
export const UpdateCollectionConfig = (id: string, data: CollectionTypes) => {
  return EditFunc(`admin/updateRuleTest/${id}`, data);
};
export const ChangeCollectionStatusConfig = (id: number, status: boolean) => {
  return EditFunc(`admin/changeStatus/${id}?status=${status}`, "", "PATCH");
};

// Teacher collection CRUD //
export const GetTeacherCollectionsConfig = (param?: string) => {
  return GetFunc(`admin/testRuleTeachers${param}`);
};
export const GetTeacherCollectionByIdConfig = (id?: string) => {
  return GetFunc(`admin/testRuleTeacherById/${id}`);
};
export const CreateTeacherCollectionConfig = (data: CollectionTeacherTypes) => {
  return CreateFunc(`admin/createRuleTeacher`, data);
};
export const UpdateTeacherCollectionConfig = (
  id: string,
  data: CollectionTeacherTypes
) => {
  return EditFunc(`admin/updateRuleTestTeacher/${id}`, data);
};
export const ChangeTeacherCollectionStatusConfig = (
  id: number,
  status: boolean
) => {
  return EditFunc(
    `admin/changeStatusTeacher/${id}?status=${status}`,
    "",
    "PATCH"
  );
};

//Test collection users //
export const GetAccessUsersConfig = (param: string) => {
  return GetFunc(`admin/getAccessUser${param}`);
};
export const DeleteAccessUserConfig = (id: number) => {
  return DeleteFunc(`admin/deleteAccessUser/${id}`);
};

//Test collection users //
export const GetAccessTeachersConfig = (param: string) => {
  return GetFunc(`admin/accessUserTeacher${param}`);
};

// Block CRUD //
export const GetBlocksConfig = (param?: string) => {
  return GetFunc(`admin/getBlocks${param}`);
};
export const GetBlockByIdConfig = (id?: string) => {
  return GetFunc(`admin/getBlockById/${id}`);
};
export const CreateBlockConfig = (param: string) => {
  return CreateFunc(`admin/createBlock?${param}`, "");
};
export const UpdateBlockConfig = (id: string, param: string) => {
  return EditFunc(`admin/updateBlock/${id}?${param}`, "", "PATCH");
};
export const DeleteBlockConfig = (id: number) => {
  return DeleteFunc(`admin/deleteBlock/${id}`);
};

// Quiz collection CRUD //
export const GetQuizRulesConfig = (param: string) => {
  return GetFunc(`admin/quiz/getQuizRules${param}`);
};
export const GetQuizRuleByIdConfig = (id?: string) => {
  return GetFunc(`admin/quiz/getQuizRuleById/${id}`);
};
export const CreateQuizRuleConfig = (data: QuizRuleTypes) => {
  return CreateFunc(`admin/quiz/createQuizRule`, data);
};
export const UpdateQuizRuleConfig = (data: QuizRuleTypes) => {
  return EditFunc(`admin/quiz/updateQuizRule`, data);
};

// Quiz CRUD //
export const GetQuizesConfig = (param: string) => {
  return GetFunc(`admin/quiz/getQuizes${param}`);
};
export const GetQuizByIdConfig = (id?: string) => {
  return GetFunc(`admin/quiz/getQuizById/${id}`);
};
export const CreateQuizConfig = (data: QuizTypes) => {
  return CreateFunc(`admin/quiz/createQuiz`, data);
};
export const UpdateQuizConfig = (id: string, data: QuizTypes) => {
  return EditFunc(`admin/quiz/updateQuiz/${id}`, data);
};
export const DeleteQuizConfig = (id: number) => {
  return DeleteFunc(`admin/quiz/deleteQuiz/${id}`);
};

// Theme CRUD //
export const GetThemesConfig = () => {
  return GetFunc(`admin/theme/getThemes`);
};
export const GetThemesPageConfig = (param?: string) => {
  return GetFunc(`admin/theme/getThemesPage${param}`);
};
export const GetThemeByIdConfig = (id?: string) => {
  return GetFunc(`admin/theme/getThemeById/${id}`);
};
export const CreateThemeConfig = (data: ThemeTypes) => {
  return CreateFunc(`admin/theme/createTheme`, data);
};
export const UpdateThemeConfig = (data: ThemeTypes) => {
  return EditFunc(`admin/theme/updateTheme`, data);
};
export const DeleteThemeConfig = (id: number) => {
  return DeleteFunc(`admin/theme/deleteTheme/${id}`);
};

//////////// User controller ////////////
export const GetUserInfoConfig = () => {
  return GetFunc(`user/getUserInfo`);
};

//Test section
export const GetUserCollectionsConfig = (param = "") => {
  return GetFunc(`user/getTestsRules${param}`);
};
export const GetUserQuestionConfig = (
  id: number,
  code: string | number | null
) => {
  return GetFunc(`user/getTestQuestion/${id}?generateCode=${code}`);
};
export const CheckUserTimeConfig = (id?: number) => {
  return GetFunc(`user/checkTimeStudent/${id}`);
};
export const GenerateTestConfig = (id: number, code: string) => {
  return CreateFunc(`user/generateTest/${id}?generateCode=${code}`, "");
};
export const SendAnswerConfig = (param = "") => {
  return EditFunc(`user/sendAnswer?${param}`, "", "PATCH");
};
export const FinishTestConfig = (id: string, code: string | number) => {
  return CreateFunc(`user/testFinishedQuestion/${id}?generateCode=${code}`, "");
};

//Quiz section
export const GetUserQuizCollectionsConfig = (param = "") => {
  return GetFunc(`user/getQuizRules${param}`);
};
export const GetUserQuizesConfig = (id?: number) => {
  return GetFunc(`user/quizAnswerResponse/${id}`);
};
export const GenerateQuizConfig = (id: number) => {
  return CreateFunc(`user/generateQuiz/${id}`, "");
};
export const SendQuizAnswerConfig = (data: QuizSendTypes) => {
  return EditFunc(`user/sendQuizAnswer`, data, "PATCH");
};
export const FinishQuizConfig = (id: string) => {
  return CreateFunc(`user/quizFinished/${id}`, "");
};

//////////// Teacher controller ////////////
export const GetTeacherRulesConfig = (param = "") => {
  return GetFunc(`teacher/testsRulesTeacher${param}`);
};
export const GetTeacherQuestionConfig = (
  id: number,
  code: string | number | null
) => {
  return GetFunc(
    `teacher/testAnswerResponseTeacher/${id}?generateCode=${code}`
  );
};
export const CheckTeacherTimeConfig = (id?: number) => {
  return GetFunc(`user/checkTimeTeacher/${id}`);
};
export const GenerateTeacherTestConfig = (id: number, code: string) => {
  return CreateFunc(
    `teacher/generateQuestionsTeacher/${id}?generateCode=${code}`,
    ""
  );
};
export const SendTeacherAnswerConfig = (param = "") => {
  return EditFunc(`teacher/sendAnswer?${param}`, "", "PATCH");
};
export const FinishTeacherTestConfig = (id: string, code: string | number) => {
  return CreateFunc(
    `teacher/testFinishedResponseTeacher/${id}?generateCode=${code}`,
    ""
  );
};
export const CheckHemisIdConfig = () => {
  return GetFunc(`teacher/checkHemisId`);
};
