import {
  Form,
  Button,
  Breadcrumb,
  Input,
  InputNumber,
  Checkbox,
  message,
  Table,
  Select,
  Modal,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { collectionItems } from "src/static/data/form_items";
import {
  CollectionTypes,
  SpecialityFormTypes,
  SpecialityResTypes,
  SpecialityTableTypes,
  SpecialityTypes,
  UniversityTypes,
} from "src/static/interface";
import { rules } from "src/static/rules";
import {
  CreateCollectionConfig,
  GetCollectionByIdConfig,
  UpdateCollectionConfig,
} from "src/service/api";
import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { specialityColumns } from "src/static/data/columns";
import {
  GetFacultiesConfig,
  GetSpecialities,
  GetUniversities,
} from "src/service/api/open";
import { adminHomeBcmb } from "src/static/data/breadcrumb_items";
import BlockFormItem from "src/components/form_item/block";

function CollectionActionsPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [specialityForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<SpecialityTableTypes[]>([]);
  const [specialities, setSpecialities] = useState<SpecialityTypes[]>([]);
  const [universities, setUniversities] = useState<UniversityTypes[]>([]);
  const [visible, setVisible] = useState(false);
  const { Option } = Select;
  const collId = searchParams.get("collId");

  const handleSet = () => {
    GetUniversities().then((res) => {
      setUniversities(res.data);
    });
    if (collId) {
      GetCollectionByIdConfig(collId).then((res: any) => makeData(res));
    } else {
      form.setFieldsValue({ active: true });
    }
  };

  const makeData = (res: any) => {
    let { testMaxCount, specialityResponses, ...rest } = res.data;
    form.setFieldsValue(rest);
    let arr: SpecialityTableTypes[] = [];
    specialityResponses.forEach((item: SpecialityResTypes) => {
      let obj = arr.find((el) => el.universityId === item.universityId);
      let obj2 = {
        id: item.specialityId,
        specialityCode: item.specialityСode,
        specialityName: item.specialityName,
      };
      if (obj) {
        obj.specialityResponses.push(obj2);
      } else {
        arr.push({
          universityId: item.universityId,
          universityName: item.universityName,
          specialityResponses: [obj2],
        });
      }
    });
    handleNumber(arr);
  };

  useEffect(() => {
    handleSet();
  }, []);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((val: CollectionTypes) => {
        let { universityId, ...rest } = val;
        let level1 = val.testLevel1 || 0;
        let level2 = val.testLevel2 || 0;
        let level3 = val.testLevel3 || 0;
        rest.testMaxCount = level1 + level2 + level3;
        if (dataSource.length === 0) {
          specialityForm
            .validateFields()
            .then((source: SpecialityFormTypes) => {
              rest.universitySpecialityId = source.universitySpecialityId;
              handleFetch(rest);
            });
        } else {
          let arr: number[] = [];
          dataSource.forEach((item) => {
            item.specialityResponses.forEach((el) => {
              arr.push(el.id);
            });
          });
          rest.universitySpecialityId = arr;
          handleFetch(rest);
        }
      })
      .catch(() => {
        if (dataSource.length === 0) specialityForm.validateFields();
      });
  };

  const handleFetch = (rest: CollectionTypes) => {
    setLoading(true);
    if (collId) {
      UpdateCollectionConfig(collId, rest)
        .then(() => {
          message.success(
            "Talablar uchun shakllangan testlar ma'lumotlari muvaffiyaqatli tahrirlandi"
          );
          navigate("/admin");
        })
        .finally(() => setLoading(false));
    } else {
      CreateCollectionConfig(rest)
        .then(() => {
          message.success(
            "Talablar uchun testlar muvvafaqiyatli shakllantirildi"
          );
          navigate("/admin");
        })
        .finally(() => setLoading(false));
    }
  };

  //Add speciality section
  const handleChange = (val: number) => {
    GetSpecialities(val).then((res) => {
      setSpecialities(res.data);
      specialityForm.setFieldsValue({ universitySpecialityId: undefined });
      let obj = dataSource.find((el) => el.universityId === val);
      if (obj) {
        let arr: number[] = [];
        obj.specialityResponses.forEach((item) => {
          arr.push(item.id);
        });
        specialityForm.setFieldsValue({ universitySpecialityId: arr });
      }
    });
    GetFacultiesConfig(val);
  };

  const handleAdd = (val: SpecialityFormTypes) => {
    let { universityId } = val;
    let arr: SpecialityTypes[] = [];
    specialities.forEach((item) => {
      if (val.universitySpecialityId.includes(item.id)) {
        arr.push(item);
      }
    });

    let obj = {
      universityId: universityId,
      universityName:
        universities.find((el) => el.id === universityId)?.name || "",
      specialityResponses: arr,
      key: 0,
    };

    let sourceArr = dataSource;
    let existObj = sourceArr.find((el) => el.universityId === universityId);
    if (existObj) existObj.specialityResponses = obj.specialityResponses;
    else sourceArr = [obj, ...sourceArr];
    handleNumber(sourceArr);

    specialityForm.resetFields();
    setSpecialities([]);
    closeModal();
  };

  const handleDelete = (val: number) => {
    let arr = dataSource.filter((el) => el.universityId !== val);
    handleNumber(arr);
  };

  const handleNumber = (arr: SpecialityTableTypes[]) => {
    arr.forEach((item, index) => {
      item.key = index + 1;
    });
    setDataSource(arr);
  };

  const openModal = (id: number) => {
    setVisible(true);
    specialityForm.setFieldsValue({
      universityId: id,
    });
    handleChange(id);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const columns: ColumnsType<SpecialityTableTypes> = [
    ...specialityColumns,
    {
      title: "Amallar",
      dataIndex: "universityId",
      key: "universityId",
      render: (universityId) => (
        <div className="layout-content-table-buttons">
          <EditOutlined onClick={() => openModal(universityId)} />
          <DeleteOutlined onClick={() => handleDelete(universityId)} />
        </div>
      ),
    },
    {
      title: "Amallar",
      dataIndex: "universityId",
      key: "universityId",
      render: (universityId) => (
        <div className="layout-content-table-buttons">
          <EditOutlined onClick={() => openModal(universityId)} />
          <DeleteOutlined onClick={() => handleDelete(universityId)} />
        </div>
      ),
    },
  ];

  const specialityFormContent = (
    <Form form={specialityForm} layout="vertical" onFinish={handleAdd}>
      <Form.Item
        name="universityId"
        label="Universitet"
        rules={[rules.university]}
      >
        <Select
          size="large"
          allowClear
          placeholder={rules.university.message}
          onChange={handleChange}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            option?.children?.toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {universities.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="universitySpecialityId"
        label="Mutaxasislik"
        rules={[rules.speciality]}
      >
        <Select
          size="large"
          mode="multiple"
          allowClear
          placeholder={rules.speciality.message}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            option?.children?.toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {specialities.map((item) => (
            <Option key={item.id} value={item.id}>
              {`${item.specialityCode} - ${item.specialityName}`}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <div className="modal-footer">
        <Button
          disabled={specialities.length === 0}
          htmlType="submit"
          type="primary"
        >
          {visible ? "O'zgartish" : "Qo'shish"}
        </Button>
      </div>
    </Form>
  );

  return (
    <div>
      <Breadcrumb
        items={[
          adminHomeBcmb,
          {
            title: (
              <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                Talabalar uchun test shakllantirish
              </div>
            ),
          },
          {
            title: collId ? "Ma'lumotlarni tahrirlash" : "Ma'lumot qo'shish",
          },
        ]}
      />
      <div className="layout-detail">
        <div className="layout-detail-header">
          Talabalar uchun test shakllantirish
        </div>
        <Form form={form} layout="vertical">
          <Form.Item name="active" valuePropName="checked">
            <Checkbox>Test aktivligi</Checkbox>
          </Form.Item>

          <BlockFormItem />

          <Form.Item name="theme" label="Test mavzusi" rules={[rules.theme]}>
            <Input size="large" placeholder={rules.theme.message} />
          </Form.Item>

          <div className="layout-form-level">
            {collectionItems.map((item) => {
              return (
                <Form.Item
                  key={item.name}
                  name={item.name}
                  label={item.label}
                  rules={item.rules}
                >
                  <InputNumber
                    min={item.name === "time" ? 1 : 0}
                    size="large"
                    placeholder={item.message}
                    className="full-width"
                  />
                </Form.Item>
              );
            })}
          </div>
        </Form>

        <div className="layout-form-speciality">{specialityFormContent}</div>
        <br />
        {dataSource.length > 0 && (
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={dataSource.length > 10 ? { pageSize: 10 } : false}
          />
        )}
        <br />
        <br />
        <div className="modal-footer">
          <Button onClick={handleSubmit} type="primary" loading={loading}>
            Ma'lumotlarni saqlash
          </Button>
        </div>
      </div>

      {/* Edit modal */}
      <Modal
        open={visible}
        title="Ma'lumotlarni tahrirlash"
        footer=""
        width={500}
        onCancel={closeModal}
      >
        {specialityFormContent}
      </Modal>
    </div>
  );
}

export default CollectionActionsPage;
