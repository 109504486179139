import { Button } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import Lottie from "react-lottie";
import * as lottiejson from "src/assets/lottie/no-access.json";

function DeniedPage() {
  const [searchaParams] = useSearchParams();
  const role = searchaParams.get("role");
  const dataLottie = {
    loop: true,
    autoplay: true,
    animationData: lottiejson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="container notfound">
      <h2 className="notfound-header">
        Kechirasiz sizda bu tizimga kirish uchun ruxsat yo'q!!!
      </h2>
      <Lottie options={dataLottie} height={300} width={400} />
      <div className="flex-center">
        <Link to={role === "user" ? "/" : "/admin"}>
          <Button type="primary">Bosh sahifaga qaytish</Button>
        </Link>
      </div>
    </div>
  );
}

export default DeniedPage;
