import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Button, Breadcrumb, message, Input, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  CreateQuizConfig,
  GetQuizByIdConfig,
  UpdateQuizConfig,
} from "src/service/api";
import { adminHomeBcmb } from "src/static/data/breadcrumb_items";
import { QuizObjTypes, QuizTypes } from "src/static/interface";
import { rules } from "src/static/rules";
import { quizTypeData } from "src/static/data";
import ThemFormItem from "src/components/form_item/theme";

function QuizActionsPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [quizObj, setQuizObj] = useState<QuizObjTypes>();
  const [disabled, setDisabled] = useState(true);
  const { TextArea } = Input;
  const { Option } = Select;
  const quizId = searchParams.get("quizId");

  const getFunc = () => {
    if (quizId) {
      setDisabled(false);
      GetQuizByIdConfig(quizId).then((res) => {
        const { data } = res;
        const { variants } = data;
        let varinat1 = variants.shift();
        let obj = {
          quiz: data.quiz,
          variant1: varinat1,
          variants: variants,
        };
        setQuizObj(obj);
        form.setFieldsValue({
          ...obj,
          themeId: data.themeId,
          buttonType: data.buttonType,
        });
      });
    }
  };

  useEffect(() => {
    getFunc();
  }, []);

  const handleSubmit = (val: QuizTypes) => {
    let { variant1, ...rest } = val;
    if (!rest.variants && variant1) rest.variants = [variant1];
    else if (variant1) {
      let arr = rest.variants;
      arr.unshift(variant1);
      rest.variants = arr;
    }
    setLoading(true);

    if (quizId) {
      UpdateQuizConfig(quizId, rest)
        .then(() => {
          message.success("So'rovnoma muvaffiyaqatli tahrirlandi");
          navigate("/admin/quiz");
        })
        .finally(() => setLoading(false));
    } else {
      CreateQuizConfig(rest)
        .then(() => {
          message.success("So'rovnoma muvaffiyaqatli qo'shildi");
          navigate("/admin/quiz");
        })
        .finally(() => setLoading(false));
    }
  };

  const handleChange = (val: string) => {
    if (val === "input") {
      setDisabled(true);
      form.setFieldsValue({ variant1: null, variants: null });
    } else setDisabled(false);
  };

  return (
    <div>
      <Breadcrumb
        items={[
          adminHomeBcmb,
          {
            title: (
              <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                So'rovnomalar
              </div>
            ),
          },
          {
            title: quizId ? "So'rovnomani tahrirlash" : "So'rovnoma qo'shish",
          },
        ]}
      />
      <div className="layout-detail">
        <div className="layout-detail-header">
          {quizId
            ? "So'rovnoma ma'lumotlarini tahrirlash"
            : "So'rovnoma qo'shish"}
        </div>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <ThemFormItem />
          <Form.Item
            name="buttonType"
            label="So'rovnoma turi"
            rules={[rules.block_s]}
          >
            <Select
              allowClear
              size="large"
              placeholder={rules.block_s.message}
              onChange={handleChange}
            >
              {quizTypeData.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="quiz"
            label="So'rovnoma savoli"
            rules={[rules.quiz_ans]}
          >
            <TextArea rows={3} placeholder={rules.quiz_ans.message} />
          </Form.Item>

          <Form.Item
            name="variant1"
            label="So'rovnoma variantlari"
            rules={disabled ? [] : [rules.quiz_v]}
          >
            <TextArea
              rows={3}
              placeholder={rules.quiz_v.message}
              disabled={disabled}
            />
          </Form.Item>
          {(quizObj || !quizId) && (
            <Form.List name="variants">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        noStyle
                      >
                        <TextArea rows={3} />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        style={{ marginBottom: 9 }}
                      />
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                      disabled={disabled}
                    >
                      Varinat qo'shish
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}

          <div className="modal-footer">
            <Button htmlType="submit" type="primary" loading={loading}>
              Saqlash
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default QuizActionsPage;
