import { useNavigate } from 'react-router-dom'
import { isUser } from 'src/static/const'
import { useContext, useState } from 'react'
import { MainContext } from 'src/context'
import { message } from 'antd'

function HomePage() {
	const navigate = useNavigate()
	const { setLoginOpen } = useContext(MainContext)
	const [play, setPlay] = useState(false)
	const handleCheck = () => {
		if (isUser) navigate('/profile')
		else {
			setLoginOpen?.(true)
			message.warning("So'rovnomani boshlash uchun iltimos tizimga kiring")
		}
	}

	return (
		<div>
			<div className='home__intro'>
				<div className='home__intro-info container'>
					<div className='home__intro-left'>
						{/* Title */}
						<h2 className='title'>
							Oliy ta'lim muassasalarida tahsil olayotgan talabalar o'rtasida{' '}
							<span>so'rovnomalar</span> o'tkazish tizimi
						</h2>

						{/* Info */}
						<p className='info'>
							Ushbu «{process.env.REACT_APP_TITLE}» tizimi oliy ta'lim
							muassasalarida tahsil olayotgan talabalar o'rtasida ta'lim
							jarayonini sifatli yo'lga qo'yishni o'rganish uchun mo'ljallangan.
						</p>

						{/* Apply button */}
						<button
							onClick={handleCheck}
							className='arrowBtn'
							style={{ width: 'auto' }}
						>
							<span>So'rovnomani boshlash</span>
						</button>
					</div>
					<div className='home__intro-right'>
						<img
							src={require('../../assets/images/home-image.png')}
							alt='author'
							className='intro1'
							draggable={false}
						/>
					</div>
				</div>
			</div>
			{/* 
      <div className="home__video container">
        <div className="home__video-info">
          <h2 className="title">Tizimidan qanday foydalaniladi?</h2>
          <p className="info">
            Tizimga Hemis yoki One Id orqali kiring va tegishli mavzudagi test
            va so'rovnomalarni bajaring.
          </p>
        </div>
        <div className="video">
          {play ? (
            <iframe
              width="520"
              height="320"
              src="https://www.youtube.com/embed/ym5AjtIeI_E?controls=0&autoplay=1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
              title="usage-video"
            ></iframe>
          ) : (
            <div className="video__frame">
              <img src={require("src/assets/images/video.png")} alt="video" />
              <div className="play">
                <svg
                  onClick={() => setPlay(true)}
                  width="117"
                  height="117"
                  viewBox="0 0 117 117"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="58.5" cy="58.5" r="58.5" fill="white" />
                  <path
                    d="M53.5294 44.6394C51.028 43.1397 49 44.3683 49 47.3814V69.6165C49 72.6325 51.028 73.8595 53.5294 72.3612L72.1233 61.2156C74.6256 59.7154 74.6256 57.2847 72.1233 55.7849L53.5294 44.6394Z"
                    fill="#1890ff"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div> */}
		</div>
	)
}

export default HomePage
