import { CreateFunc } from "../config/multipart";

export const CreateTestByExcelConfig = (param: string, data: any) => {
  return CreateFunc(`admin/converterQuestion?${param}`, data);
};
export const AddAccessUserConfig = (id: string, data: any) => {
  return CreateFunc(`admin/createAccessUser?testRuleId=${id}`, data);
};
export const AddAccessTeacherConfig = (id: string, data: any) => {
  return CreateFunc(`admin/createAccessTeacher?testRuleTeacherId=${id}`, data);
};
