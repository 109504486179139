export const quizTypeData = [
  {
    name: "Bitta variant tanlanadigan",
    value: "radio",
  },
  {
    name: "Bir nechta varinat tanlanadigan",
    value: "checkbox",
  },
  {
    name: "Izoh yozadigan",
    value: "input",
  },
];

export const ratingData = [
  {
    key: "1",
    name: "Avazbek",
    phone: "*******2906",
    region: "Farg'ona",
    subject: "Matematika",
    time: "47 daqiqa 10 soniya",
    total: 29,
  },
  {
    key: "2",
    name: "Muhammadjon",
    phone: "*******3787",
    region: "Farg'ona",
    subject: "Informatika",
    time: "50 daqiqa",
    total: 29,
  },
  {
    key: "3",
    name: "Mahammadov",
    phone: "*******0155",
    region: "Farg'ona",
    subject: "Fizika",
    time: "40 daqiqa",
    total: 28,
  },
  {
    key: "4",
    name: "Akmaljon",
    phone: "*******7199",
    region: "Farg'ona",
    subject: "Matematika",
    time: "45 daqiqa",
    total: 28,
  },
  {
    key: "5",
    name: "Husanboy",
    phone: "*******7481",
    region: "Farg'ona",
    subject: "Kimyo",
    time: "51 daqiqa",
    total: 25,
  },
  {
    key: "6",
    name: "Javharbek",
    phone: "*******8584",
    region: "Farg'ona",
    subject: "Informatika",
    time: "57 daqiqa",
    total: 25,
  },
  {
    key: "7",
    name: "Asror",
    phone: "*******8898",
    region: "Farg'ona",
    subject: "Matematika",
    time: "20 daqiqa",
    total: 22,
  },
];

export const regions = [
  {
    name: "Toshkent shahri",
    id: 1,
  },
  {
    name: "Toshkent viloyati",
    id: 2,
  },
  {
    name: "Farg'ona",
    id: 3,
  },
  {
    name: "Andijon",
    id: 4,
  },
  {
    name: "Buxoro",
    id: 5,
  },
  {
    name: "Jizzax",
    id: 6,
  },
  {
    name: "Xorazm",
    id: 7,
  },
  {
    name: "Namangan",
    id: 8,
  },
  {
    name: "Navoiy",
    id: 9,
  },
  {
    name: "Qashaqadaryo",
    id: 10,
  },
  {
    name: "Samarqand",
    id: 11,
  },
  {
    name: "Sirdaryo",
    id: 12,
  },
  {
    name: "Surxandaryo",
    id: 13,
  },
  {
    name: "Qoraqalpog'iston Respublikasi",
    id: 14,
  },
];
