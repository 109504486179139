import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Button, Breadcrumb, Input, message, Select } from "antd";
import {
  CreateThemeConfig,
  GetThemeByIdConfig,
  UpdateThemeConfig,
} from "src/service/api";
import { adminHomeBcmb } from "src/static/data/breadcrumb_items";
import { LanguageTypes, ThemeTypes } from "src/static/interface";
import { rules } from "src/static/rules";
import { GetLanguages } from "src/service/api/open";

function ThemeActionsPage() {
  const navigate = useNavigate();
  const { Option } = Select;
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [lanuages, setLanugages] = useState<LanguageTypes[]>([]);
  const themeId = searchParams.get("themeId");

  const getFunc = () => {
    GetLanguages().then((res) => {
      setLanugages(res.data);
    });
    if (themeId) {
      GetThemeByIdConfig(themeId).then((res: { data: ThemeTypes }) => {
        form.setFieldsValue({
          name: res.data.name,
          languageCode: res.data.languageCode,
        });
      });
    }
  };

  useEffect(() => {
    getFunc();
  }, []);

  const handleSubmit = (val: ThemeTypes) => {
    const { languageCode } = val;
    let language = lanuages.find((el) => el.code === languageCode)?.name;
    if (language) val.language = language;
    setLoading(true);
    if (themeId) {
      val.id = +themeId;
      UpdateThemeConfig(val)
        .then(() => {
          message.success("Test mavzusi muvaffiyaqatli tahrirlandi");
          navigate("/admin/theme");
        })
        .finally(() => setLoading(false));
    } else {
      CreateThemeConfig(val)
        .then(() => {
          message.success("Test mavzusi muvaffiyaqatli qo'shildi");
          navigate("/admin/theme");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div>
      <Breadcrumb
        items={[
          adminHomeBcmb,
          {
            title: (
              <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                So'rovnoma mavzulari
              </div>
            ),
          },
          {
            title: themeId ? "Mavzuni tahrirlash" : "Mavzu qo'shish",
          },
        ]}
      />
      <div className="layout-detail">
        <div className="layout-detail-header">
          {themeId
            ? "So'rovnoma mavzusini tahrirlash"
            : "So'rovnoma mavzusini qo'shish"}
        </div>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="name" label="Mavzu nomi" rules={[rules.theme_name]}>
            <Input
              allowClear
              size="large"
              placeholder={rules.theme_name.message}
            />
          </Form.Item>

          <Form.Item
            name="languageCode"
            label="Mavzu tili"
            rules={[rules.theme_lan]}
          >
            <Select
              allowClear
              size="large"
              placeholder={rules.theme_lan.message}
            >
              {lanuages.map((item) => (
                <Option key={item.id} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className="modal-footer">
            <Button htmlType="submit" type="primary" loading={loading}>
              Saqlash
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ThemeActionsPage;
