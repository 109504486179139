import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { GetBlocksConfig } from "src/service/api";
import { LanguageTypes } from "src/static/interface";
import { rules } from "src/static/rules";

function BlockFormItem() {
  const { Option } = Select;
  const [blocks, setBlocks] = useState<LanguageTypes[]>([]);
  const getFunc = () => {
    GetBlocksConfig("?page=1&size=10000").then((res) => {
      setBlocks(res.data.content);
    });
  };
  useEffect(() => {
    getFunc();
  }, []);
  return (
    <Form.Item name="blockId" label="Blok" rules={[rules.block_s]}>
      <Select
        allowClear
        size="large"
        placeholder={rules.block_s.message}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option: any) =>
          option?.children?.toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {blocks.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default BlockFormItem;
