import { useEffect } from "react";
import { SignInConfig } from "src/service/api/open";
import { handleLoginFunc } from "src/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { message } from "antd";

function QabulJsp() {
  const [searchaParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchaParams.get("code");
  const handleLogin = () => {
    if (!!code) {
      SignInConfig(code)
        .then((res) => {
          let { object } = res.data;
          handleLoginFunc(object);
        })
        .catch((error) => {
          let text = error?.response?.data?.message;
          const status = error?.response?.status
          if (status === 404) {
            navigate("/denied?role=user")
          } else{
            message.error(text);
            navigate("/");
          }
        });
    }
  };
  useEffect(() => {
    handleLogin();
  }, []);

  return <div></div>;
}

export default QabulJsp;
