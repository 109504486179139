import { message } from "antd";
import {
  ROLE,
  ROLE_A,
  ROLE_U,
  TOKEN,
  USERNAME,
  USER_ID,
  USER_POSITION,
} from "src/static/const";
import { LoginResTypes } from "src/static/interface";

// Format
// Pretty date
export const prettyDate = (time = "29-10-2022 15:20:38", full = true) => {
  const date = new Date(time);
  const monthes: { [id: number]: string } = {
    0: "Yanvar",
    1: "Fevral",
    2: "Mart",
    3: "Aprel",
    4: "May",
    5: "Iyun",
    6: "Iyul",
    7: "Avgust",
    8: "Sentyabr",
    9: "Oktyabr",
    10: "Noyabr",
    11: "Dekabr",
  };
  if (full) {
    return `${date.getDate()} ${monthes[date.getMonth()]}, ${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()}`;
  } else {
    return `${date.getDate()} ${monthes[date.getMonth()]}`;
  }
};

// Pretty phone
export const PrettyPhone = (phone: string) => {
  return `998${phone.replace("(", "").replace(")", "").replaceAll(" ", "")}`;
};

//Auth actions
export const handleLogoutFunc = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(ROLE);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(USERNAME);
  localStorage.removeItem(USER_POSITION);
  window.location.href = "/";
};

export const handleLoginFunc = (data: LoginResTypes, role?: string) => {
  localStorage.setItem(TOKEN, data.jwtToken);
  localStorage.setItem(USER_ID, data.id.toString());
  localStorage.setItem(USERNAME, data.username);
  if (role) localStorage.setItem(ROLE, role);
  else localStorage.setItem(ROLE, data.role[0]);

  if (data.role[0] === ROLE_A) window.location.reload();
  else if (data.role[0] === ROLE_U || role === ROLE_U)
    window.location.href = "/profile";
};

export const blockDevTools = () => {
  document.addEventListener("contextmenu", (e) => e.preventDefault());
  const ctrlShiftKey = (e: any, keyCode: any) => {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  };
  document.onkeydown = (e: any) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.keyCode === 123 ||
      ctrlShiftKey(e, "I") ||
      ctrlShiftKey(e, "J") ||
      ctrlShiftKey(e, "C") ||
      (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
    )
      return false;
  };
};

export const catchFunc = (error: any) => {
  if (error.response) {
    let obj = error.response.data;
    let { status } = error.response;
    if (status === 403 || status === 401) {
      handleLogoutFunc();
    } else if (status === 404) {
      message.error(
        "Ma'lumotlar topilmadi, iltimos qayta tekshirib ko'ring",
        5
      );
    } else if (status === 500) {
      message.error(
        "Server bilan bog'liq xatolik yuz berdi. Bu haqda ma'sul xodimlarga habar bering",
        5
      );
    } else {
      if (obj?.message) {
        message.error(obj.message, 10);
      } else if (obj.length > 0) {
        obj.forEach((item: any) => {
          message.error(item);
        });
      } else {
        for (let key in obj) {
          obj[key].forEach((item: any) => {
            message.error(item);
          });
        }
      }
    }
  }
};
