import {
  Form,
  Button,
  Breadcrumb,
  Input,
  InputNumber,
  Checkbox,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { collectionItems } from "src/static/data/form_items";
import { CollectionTeacherTypes } from "src/static/interface";
import { rules } from "src/static/rules";
import {
  CreateTeacherCollectionConfig,
  GetTeacherCollectionByIdConfig,
  UpdateTeacherCollectionConfig,
} from "src/service/api";
import { adminHomeBcmb } from "src/static/data/breadcrumb_items";
import BlockFormItem from "src/components/form_item/block";

function TeacherCollectionActionsPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const collId = searchParams.get("collId");

  const handleSet = () => {
    if (collId) {
      GetTeacherCollectionByIdConfig(collId).then(
        (res: { data: CollectionTeacherTypes }) => {
          form.setFieldsValue(res.data);
        }
      );
    } else {
      form.setFieldsValue({ active: true });
    }
  };

  useEffect(() => {
    handleSet();
  }, []);

  const handleSubmit = (val: CollectionTeacherTypes) => {
    val.testMaxCount = val.testLevel1 + val.testLevel2 + val.testLevel3;
    setLoading(true);
    if (collId) {
      UpdateTeacherCollectionConfig(collId, val)
        .then(() => {
          message.success(
            "O'qituvchilar uchun shakllangan testlar ma'lumotlari muvaffiyaqatli tahrirlandi"
          );
          navigate("/admin/teacher");
        })
        .finally(() => setLoading(false));
    } else {
      CreateTeacherCollectionConfig(val)
        .then(() => {
          message.success(
            "O'qituvchilar uchun testlar muvvafaqiyatli shakllantirildi"
          );
          navigate("/admin/teacher");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div>
      <Breadcrumb
        items={[
          adminHomeBcmb,
          {
            title: (
              <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                O'qituvchilar uchun test shakllantirish
              </div>
            ),
          },
          {
            title: collId ? "Ma'lumotlarni tahrirlash" : "Ma'lumot qo'shish",
          },
        ]}
      />
      <div className="layout-detail">
        <div className="layout-detail-header">
          O'qituvchilar uchun test shakllantirish
        </div>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox>Test aktivligi</Checkbox>
          </Form.Item>

          <BlockFormItem />

          <Form.Item name="theme" label="Test mavzusi" rules={[rules.theme]}>
            <Input size="large" placeholder={rules.theme.message} />
          </Form.Item>

          <div className="layout-form-level">
            {collectionItems.map((item) => {
              return (
                <Form.Item
                  key={item.name}
                  name={item.name}
                  label={item.label}
                  rules={item.rules}
                >
                  <InputNumber
                    min={item.name === "time" ? 1 : 0}
                    size="large"
                    placeholder={item.message}
                    className="full-width"
                  />
                </Form.Item>
              );
            })}
          </div>
          <div className="modal-footer">
            <Button htmlType="submit" type="primary" loading={loading}>
              Ma'lumotlarni saqlash
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default TeacherCollectionActionsPage;
