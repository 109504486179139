import { Button, Modal, Table, message } from "antd";
import { useEffect, useState } from "react";
import { BlockResTypes, QuizResTypes } from "src/static/interface";
import { DeleteQuizConfig, GetQuizesConfig } from "src/service/api";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { quizColumns } from "src/static/data/columns";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import DeleteModal from "src/components/modal/DeleteModal";
import PaginationFilter from "src/components/filter/PaginationFilter";
import SearchFilter from "src/components/filter/SearchFilter";

function QuizPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [id, setId] = useState(0);
  const [total, setTotal] = useState(0);
  const [render, setRender] = useState(1);
  const [dataSource, setDataSource] = useState<QuizResTypes[]>([]);
  const [loading, setLoading] = useState(false);

  const handleMakeUrl = () => {
    let url = `?`;
    for (let key of Array.from(searchParams.keys())) {
      let value = searchParams.get(key);
      url = url + `&${key}=${value}`;
    }
    return url === "?" ? "" : url;
  };

  const getFunc = () => {
    setLoading(true);
    let url = handleMakeUrl();
    GetQuizesConfig(url)
      .then((res) => {
        const arr = res.data.content;
        let size = searchParams.get("size");
        let page = searchParams.get("page");
        arr.forEach((item: BlockResTypes, index: number) => {
          item.key = page && size ? index + 1 + (+page - 1) * +size : index + 1;
        });
        setDataSource(arr);
        setTotal(res.data.totalElements);
        window.scrollTo(0, 0);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getFunc();
  }, [searchParams]);

  //Delete Data section
  const openDeleteModal = (Id: number) => {
    if (Id) {
      setId(Id);
      setRender(render + 1);
      setVisibleDelete(true);
    }
  };

  const closeDeleteModal = () => {
    setVisibleDelete(false);
    setId(0);
  };

  const handleDeleteFunc = () => {
    if (id)
      DeleteQuizConfig(id).then(() => {
        closeDeleteModal();
        message.success(`So'rovnoma muvaffaqiyatli o'chirildi`);
        getFunc();
      });
  };

  const columns: ColumnsType<QuizResTypes> = [
    ...quizColumns,
    {
      title: "Amallar",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <div className="layout-content-table-buttons">
          <EditOutlined
            onClick={() => {
              navigate(`actions?quizId=${id}`);
            }}
          />
          <DeleteOutlined
            onClick={() => {
              openDeleteModal(id);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="layout-content-header">
        <p>So'rovnomalar</p>
        <div className="right">
          <SearchFilter />
          <Link to="actions">
            <Button icon={<PlusOutlined />} type="primary">
              Qo'shish
            </Button>
          </Link>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        loading={loading}
      />
      <PaginationFilter total={total} />
      <div className="layout-content-total">
        Jami: <span>{total}</span>
      </div>

      {/* Delete modal */}
      <Modal
        open={visibleDelete}
        title=""
        footer=""
        width={500}
        onCancel={closeDeleteModal}
      >
        <DeleteModal
          text="So'rovnoma"
          render={render}
          closeModal={closeDeleteModal}
          deleteFunc={handleDeleteFunc}
        />
      </Modal>
    </div>
  );
}

export default QuizPage;
