import * as faq from "src/assets/lottie/faq.json";
import * as notfound from "src/assets/lottie/404.json";
import * as nodata from "src/assets/lottie/nodata.json";
import * as nodata2 from "src/assets/lottie/nodata2.json";
import * as success from "src/assets/lottie/success.json";
import * as loading from "src/assets/lottie/loading.json";
import * as noAccess from "src/assets/lottie/no-access.json";
import * as deleteAnimation from "src/assets/lottie/delete.json";
import * as mainLoading from "src/assets/lottie/main-loading.json";
import * as dotAnimation from "src/assets/lottie/dot-animation.json";

export const faqLottie = {
  loop: true,
  autoplay: true,
  animationData: faq,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const notFoundLottie = {
  loop: true,
  autoplay: true,
  animationData: notfound,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const noDataLottie = {
  loop: true,
  autoplay: true,
  animationData: nodata,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const noData2Lottie = {
  loop: true,
  autoplay: true,
  animationData: nodata2,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const successLottie = {
  loop: true,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const loadingLottie = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const noAccessLottie = {
  loop: true,
  autoplay: true,
  animationData: noAccess,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const deletelLottie = {
  loop: true,
  autoplay: true,
  animationData: deleteAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const mainLoadingLottie = {
  loop: true,
  autoplay: true,
  animationData: mainLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const dotAnimationLottie = {
  loop: true,
  autoplay: true,
  animationData: dotAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
