import { Button, Modal, Tabs } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { QuizUserCollResTypes } from "src/static/interface";
import {
  GenerateQuizConfig,
  GetUserQuizCollectionsConfig,
} from "src/service/api";
import { Link } from "react-router-dom";
import {
  PlayCircleOutlined,
  RetweetOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import Lottie from "src/components/lottie";
import { MainContext } from "src/context";
import moment from "moment";

function QuizesPage() {
  const navigate = useNavigate();
  const { handleMakeParams } = useContext(MainContext);
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<QuizUserCollResTypes[]>([]);

  const getFunc = () => {
    let url = `?status=${searchParams.get("status") || "Boshlanmagan"}`;
    GetUserQuizCollectionsConfig(url).then((res) => {
      let { content } = res.data;
      setData(content);
    });
  };

  useEffect(() => {
    getFunc();
  }, [searchParams.get("status")]);

  //Tab section
  const onChange = (newActiveKey: string) => {
    handleMakeParams?.("status", newActiveKey);
  };

  //Tab content1
  const startTest = (id: number) => {
    Modal.confirm({
      title: "So'rovnomani boshlashni tasdiqlaysizmi?",
      okText: "Ha",
      cancelText: "Bekor qilish",
      onOk() {
        GenerateQuizConfig(id).then(() => {
          navigate(`/quizes/solve?id=${id}`);
        });
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  const returnContent = () => {
    return (
      <>
        {data.length > 0 ? (
          data.map((item, index) => (
            <div key={crypto?.randomUUID()} className="testinfo__card">
              <div className="testinfo__card__left">
                <h2>{item.quizRuleProjection.theme}</h2>
                <p>
                  Tugash vaqti:{" "}
                  <span>
                    {moment(item.quizRuleProjection.endTime).format(
                      "YYYY-MM-DD k:mm"
                    )}
                  </span>
                </p>
              </div>

              <div className="testinfo__card__right">
                {item.status === "Boshlanmagan" && (
                  <Button
                    type="primary"
                    onClick={() => startTest(item.quizRuleProjection.id)}
                    icon={<PlayCircleOutlined />}
                  >
                    So'rovnomani boshlash
                  </Button>
                )}
                {item.status === "Jarayonda" && (
                  <Link to={`/quizes/solve?id=${item.quizRuleProjection.id}`}>
                    <Button type="primary" icon={<RetweetOutlined />}>
                      So'rovnomani davom ettirish
                    </Button>
                  </Link>
                )}
                {item.status === "Yakunlangan" && (
                  <Link
                    to={`/profile/quizes/result?id=${item.quizRuleProjection.id}`}
                  >
                    <Button type="primary" icon={<SwapRightOutlined />}>
                      Batafsil
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          ))
        ) : (
          <Lottie />
        )}
      </>
    );
  };

  const tabItems = [
    {
      label: "Yangi so'rovnomalar",
      key: "Boshlanmagan",
      children: returnContent(),
    },
    {
      label: "Jarayondagi so'rovnomalar",
      key: "Jarayonda",
      children: returnContent(),
    },
    {
      label: "Yakunlangan so'rovnomalar",
      key: "Yakunlangan",
      children: returnContent(),
    },
  ];

  return (
    <div className="testinfo">
      <Tabs
        type="card"
        onChange={onChange}
        activeKey={searchParams.get("status") || "Boshlanmagan"}
        items={tabItems}
        style={{ width: "100%" }}
      />
    </div>
  );
}

export default QuizesPage;
