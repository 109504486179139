import {
  faqLottie,
  noDataLottie,
  noData2Lottie,
  notFoundLottie,
  dotAnimationLottie,
} from "src/assets/lottie";
import Lottie from "react-lottie";

function LottieAnimation({
  name = "nodata",
  title = "Ma'lumotlar topilmadi",
}) {
  if (name === "faq") {
    return <Lottie options={faqLottie} width={302} />;
  } else if (name === "nodata2") {
    return <Lottie options={noData2Lottie} height={210} width={302} />;
  } else if (name === "notfound") {
    return <Lottie options={notFoundLottie} height={350} width={320} />;
  } else if (name === "dotAnimation") {
    return <Lottie options={dotAnimationLottie} height="100%" width="100%" />;
  } else {
    return (
      <div className="nodata-anim" style={{ width: "100%" }}>
        <Lottie options={noDataLottie} height={300} width={300} />
        <h2 style={{ textAlign: "center", marginTop: -72 }}>{title}</h2>
      </div>
    );
  }
}

export default LottieAnimation;
