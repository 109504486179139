import {
  AppstoreOutlined,
  DatabaseOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { FileSVG, FolderSVG, UserSVG } from "src/assets/svg";

export const adminMenuItems = [
  {
    label: "Testlar",
    icon: <QuestionCircleOutlined />,
    key: "/admin",
  },
  {
    label: "Bloklar",
    icon: <AppstoreOutlined />,
    key: "/admin/block",
  },
  {
    label: "To'plamlar",
    icon: <DatabaseOutlined />,
    key: "/admin/collection",
  },
];

export const profileMenuItems = [
  // {
  //   title: "Testlar",
  //   icon: <FileSVG />,
  //   link: "/profile",
  // },
  {
    title: "So'rovnomalar",
    icon: <FolderSVG />,
    link: "/profile/quizes",
  },
  {
    title: "Ma'lumot",
    icon: <UserSVG />,
    link: "/profile/informs",
  },
];

export const profileTeacherMenuItems = [
  {
    title: "So'rovnomalar",
    icon: <FileSVG />,
    link: "/profile/quizes",
  },
  {
    title: "Shaxsiy ma'lumotlar",
    icon: <UserSVG />,
    link: "/profile/informs",
  },
];
