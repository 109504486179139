import { useState } from "react";
import { Button, Form, Input, message } from "antd";

import { LoginTypes } from "src/static/interface";
import LottieAnimation from "src/components/lottie";
import { Link } from "react-router-dom";
import { rules } from "src/static/rules";
import { SignInAdminConfig } from "src/service/api/open";
import { handleLoginFunc } from "src/utils";

function AdminLoginPage() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (val: LoginTypes) => {
    setLoading(true);
    SignInAdminConfig(val)
      .then((res) => {
        handleLoginFunc(res.data.object);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="login-admin">
      <div className="login-admin-top">
        <LottieAnimation name="dotAnimation" />
      </div>

      <div className="login-admin-bottom">
        <div className="login-admin-bottom-left"></div>
        <div className="login-admin-bottom-right"></div>
      </div>

      <div className="login-admin-content">
        <div className="login-admin-content-header">
          <Link to="/">
            <a>Test.edu.uz</a>
          </Link>
          <h2>Test va sorovnomalar o'tkazish platformasi</h2>
        </div>
        <div className="login-admin-content-form">
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Form.Item
              label="Foydalanuvchi nomi"
              name="login"
              rules={[rules.login]}
            >
              <Input
                placeholder={rules.login.message}
                type="text"
                size="large"
              />
            </Form.Item>

            <Form.Item
              label="Foydalanuvchi paroli"
              name="password"
              rules={[rules.password]}
            >
              <Input.Password
                placeholder={rules.password.message}
                type="text"
                size="large"
                onPaste={(e) => e.preventDefault()}
              />
            </Form.Item>

            <Button
              type="primary"
              size="large"
              className="full-width main-button"
              htmlType="submit"
              loading={loading}
            >
              Kirish
            </Button>
          </Form>
        </div>
      </div>

      <div className="login-admin-footer">
        © 2023 Raqamli ta'lim texnologiyalarini rivojlantirish markazi
      </div>
    </div>
  );
}

export default AdminLoginPage;
