import { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import { MainContext } from "src/context";
import { useSearchParams } from "react-router-dom";
import { LanguageTypes } from "src/static/interface";
import { rules } from "src/static/rules";
import { GetUniversities } from "src/service/api/open";

function UniversityFilter() {
  const { handleMakeParams } = useContext(MainContext);
  const [searchParams] = useSearchParams();
  const universityId = searchParams.get("universityId");

  const { Option } = Select;

  const [universities, setUniversities] = useState<LanguageTypes[]>([]);
  const [value, setValue] = useState<string | null>(null);

  const handleChange = (val: string) => {
    setValue(val);
    handleMakeParams?.("universityId", val);
    handleMakeParams?.("page", "1");
  };

  const getFunc = () => {
    GetUniversities().then((res) => {
      setUniversities(res.data);
      universityId && setValue(universityId);
    });
  };

  useEffect(() => {
    getFunc();
  }, []);

  return (
    <Select
      value={value}
      allowClear
      placeholder={rules.university.message}
      showSearch
      onChange={handleChange}
      style={{ width: 350 }}
      optionFilterProp="children"
      filterOption={(input, option: any) =>
        option?.children?.toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        optionA.children
          .toLowerCase()
          .localeCompare(optionB.children.toLowerCase())
      }
    >
      {universities.map((item) => (
        <Option key={item.id} value={String(item.id)}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
}

export default UniversityFilter;
