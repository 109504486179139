import { Button, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { QuizRuleResTypes, UniversityTypes } from "src/static/interface";
import { GetQuizRulesConfig } from "src/service/api";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  quizCollectionColumns,
  universityColumns,
} from "src/static/data/columns";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import PaginationFilter from "src/components/filter/PaginationFilter";
import SearchFilter from "src/components/filter/SearchFilter";

function QuizCollectionPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<QuizRuleResTypes[]>([]);
  const [dataSource2, setDataSource2] = useState<UniversityTypes[]>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [theme, setTheme] = useState("");

  const openModal = (id: number) => {
    setVisible(true);
    let obj = dataSource.find((el) => el.id === id);
    if (obj) {
      setTheme(obj.theme);
      let arr = obj.universities;
      arr.forEach((item, index) => {
        item.key = index + 1;
      });
      setDataSource2(arr);
    }
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handleMakeUrl = () => {
    let url = `?`;
    for (let key of Array.from(searchParams.keys())) {
      let value = searchParams.get(key);
      url = url + `&${key}=${value}`;
    }
    return url === "?" ? "" : url;
  };

  const getFunc = () => {
    setLoading(true);
    let url = handleMakeUrl();
    GetQuizRulesConfig(url)
      .then((res) => {
        const arr = res.data.content;
        let size = searchParams.get("size");
        let page = searchParams.get("page");
        arr.forEach((item: QuizRuleResTypes, index: number) => {
          item.key = page && size ? index + 1 + (+page - 1) * +size : index + 1;
        });
        setDataSource(arr);
        setTotal(res.data.totalElements);
        window.scrollTo(0, 0);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getFunc();
  }, [searchParams]);

  const columns: ColumnsType<QuizRuleResTypes> = [
    ...quizCollectionColumns,
    {
      title: "Universitetlar",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (id) => (
        <Button type="primary" onClick={() => openModal(id)}>
          Batafsil
        </Button>
      ),
    },
    {
      title: "Amallar",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (id) => (
        <div className="layout-content-table-buttons">
          <EditOutlined
            onClick={() => {
              navigate(`actions?collId=${id}`);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="layout-content-header">
        <p>So'rovnoma shakllantirish</p>
        <div className="right">
          <SearchFilter />
          <Link to="actions">
            <Button icon={<PlusOutlined />} type="primary">
              Qo'shish
            </Button>
          </Link>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        loading={loading}
      />
      <PaginationFilter total={total} />
      <div className="layout-content-total">
        Jami: <span>{total}</span>
      </div>

      {/* Universities modal */}
      <Modal
        open={visible}
        title={`${theme} mavzusidagi so'rovnomalar uchun biriktirilgan universitetlar`}
        footer=""
        width={520}
        onCancel={closeModal}
      >
        <br />
        {dataSource2.length > 0 && (
          <Table
            dataSource={dataSource2}
            columns={universityColumns}
            pagination={dataSource2.length > 10 ? { pageSize: 10 } : false}
          />
        )}
      </Modal>
    </div>
  );
}

export default QuizCollectionPage;
