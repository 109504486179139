import { Select, Form, Button, Breadcrumb, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CreateAdminTestConfig,
  GetAdminTestByIdConfig,
  UpdateAdminTestConfig,
} from "src/service/api";
import { adminHomeBcmb } from "src/static/data/breadcrumb_items";
import { testAddItems } from "src/static/data/form_items";
import { TestFormTypes, TestObjTypes } from "src/static/interface";
import { rules } from "src/static/rules";
import { sunEditorOptions } from "src/static/const";
import SunEditor from "suneditor-react";
import BlockFormItem from "src/components/form_item/block";

function TestActionsPage() {
  const navigate = useNavigate();
  const { Option } = Select;
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [testObj, setTestObj] = useState<TestObjTypes>();
  const testId = searchParams.get("testId");

  const getFunc = () => {
    if (testId) {
      GetAdminTestByIdConfig(testId).then((res) => {
        const { data } = res;
        let obj = {
          question: data.question,
          variant1: data.variants[0],
          variant2: data.variants[1],
          variant3: data.variants[2],
          variant4: data.variants[3],
        };
        setTestObj(obj);
        form.setFieldsValue({ obj, blockId: data.blockId, level: data.level });
      });
    }
  };

  useEffect(() => {
    getFunc();
  }, []);

  const handleSubmit = (val: TestFormTypes) => {
    let obj = {
      blockId: val.blockId,
      level: val.level,
      question: val.question,
      answer: val.variant1,
      variants: [val.variant1, val.variant2, val.variant3, val.variant4],
    };

    setLoading(true);
    if (testId) {
      UpdateAdminTestConfig(testId, obj)
        .then(() => {
          message.success("Test ma'lumotlari muvaffiyaqatli tahrirlandi");
          navigate("/admin/test");
        })
        .finally(() => setLoading(false));
    } else {
      CreateAdminTestConfig(obj)
        .then(() => {
          message.success("Test muvaffiyaqatli qo'shildi");
          navigate("/admin/test");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div>
      <Breadcrumb
        items={[
          adminHomeBcmb,
          {
            title: (
              <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                Testlar
              </div>
            ),
          },
          {
            title: testId ? "Test tahrirlash" : "Test qo'shish",
          },
        ]}
      />
      <div className="layout-detail">
        <div className="layout-detail-header">
          {testId ? "Test ma'lumotlarini tahrirlash" : "Test qo'shish"}
        </div>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <BlockFormItem />
          <Form.Item
            name="level"
            label="Qiyinlik darajasi"
            rules={[rules.dificulty]}
          >
            <Select
              allowClear
              size="large"
              placeholder={rules.dificulty.message}
            >
              {[1, 2, 3].map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {testAddItems.map((item, index) => {
            return (
              <Form.Item
                key={index}
                name={item.name}
                label={item.label}
                rules={item.rules}
              >
                <SunEditor
                  setOptions={sunEditorOptions}
                  setContents={
                    testObj
                      ? testObj[item.name as keyof TestObjTypes]
                      : undefined
                  }
                />
              </Form.Item>
            );
          })}
          <div className="modal-footer">
            <Button htmlType="submit" type="primary" loading={loading}>
              Saqlash
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default TestActionsPage;
