import { BottomArrowSVG } from "src/assets/svg";
import { Dropdown, Modal } from "antd";
import { handleLogoutFunc } from "src/utils/index";
import { MainContext } from "src/context";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { isUser, userPosition } from "src/static/const";
import { GetUserInfoConfig } from "src/service/api";
import {
  profileMenuItems,
  profileTeacherMenuItems,
} from "src/static/data/menu_items";
import Login from "../login";
import ProfileButton from "../common/button/ProfileButton";

function Header() {
  const { userInfo, setUserInfo, profileOpen, setProfileOpen } =
    useContext(MainContext);

  // Check user from local storage
  const checkUser = () => {
    if (isUser && !userInfo) {
      GetUserInfoConfig().then((res) => {
        let { data } = res;
        let arr = data.fullName.split(" ");
        let obj = {
          name: arr[0][0] + arr[1][0],
          ...data,
        };
        setUserInfo?.(obj);
      });
    }
  };

  const confirmFunc = () => {
    Modal.confirm({
      title: "Tizimdan chiqishni tasdiqlaysizmi?",
      okText: "Ha",
      cancelText: "Bekor qilish",
      onOk() {
        handleLogoutFunc();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  // Profile menu
  const menu = (
    <div className="kirgan__menu">
      {(userPosition === "TEACHER"
        ? profileTeacherMenuItems
        : profileMenuItems
      ).map((item) => {
        return (
          <ProfileButton
            svg={item.icon}
            title={item.title}
            link={item.link}
            key={crypto?.randomUUID()}
          />
        );
      })}

      <button className="logout" onClick={confirmFunc}>
        Chiqish
      </button>
    </div>
  );

  useEffect(() => {
    checkUser();
  }, []);

  return (
    // Header section
    <div className="header">
      <div className="container">
        {/* Logo side */}
        <Link to="/" className="flex logo">
          <>
            <img src={require("src/assets/images/logo.png")} alt="author" />
            <span>{process.env.REACT_APP_TITLE}</span>
          </>
        </Link>

        {isUser ? (
          <div className="header_hemisId">
            {/* Hemis Id: <span>{userInfo?.hemisId}</span> */}
          </div>
        ) : (
          ""
        )}

        {/* Navbar */}
        <div className="header__nav">
          {/* Profile section */}
          <div className="header__profile">
            {!isUser ? (
              <Login />
            ) : (
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
                open={profileOpen}
              >
                <div className="kirgan" onClick={() => setProfileOpen?.(true)}>
                  <div className="kirgan__img">
                    {userInfo?.name ? `${userInfo?.name}` : "AA"}
                  </div>
                  <span>Profil</span>
                  <BottomArrowSVG />
                </div>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
