import { Button } from "antd";
import { Link } from "react-router-dom";
import LottieAnimation from "src/components/lottie";

function Custom404() {
  return (
    <div className="container notfound">
      <h2 className="notfound-header  notfound-header-404">
        Kechirasiz siz tashrif buyurgan sahifa mavjud emas
      </h2>
      <LottieAnimation name="notfound" />
      <br />
      <div className="flex-center">
        <Link to="/">
          <Button size="large" type="primary">
            Bosh sahifaga qaytish
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default Custom404;
