import { CloseSvg } from "src/assets/svg";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { TestTypes } from "src/static/interface";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Drawer, Modal, Progress, Statistic, message } from "antd";
import {
  FinishTeacherTestConfig,
  FinishTestConfig,
  GetTeacherQuestionConfig,
  GetUserQuestionConfig,
  SendAnswerConfig,
  SendTeacherAnswerConfig,
} from "src/service/api";
import Test from "src/components/test";
import { MainContext } from "src/context";
import { USER_CODE, userCode, userPosition } from "src/static/const";

interface PageProps {
  location?: string;
  test_id?: string;
  test_theme?: string;
}

function TestPage({ location = "test", test_id, test_theme }: PageProps) {
  const navigate = useNavigate();
  const { generateCode, setGenerateCode } = useContext(MainContext);
  const [searchParams] = useSearchParams();
  const testId = searchParams.get("id") || test_id;
  const theme = searchParams.get("theme") || test_theme;
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<TestTypes[]>([]);
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState<number | undefined>();
  const [endTime, setEndTime] = useState("");
  const [duration, setDuration] = useState<number>(36000);
  let code = generateCode || userCode;

  //Check internet
  const checkInternet = () => {
    if (!navigator.onLine) window.location.reload();
  };

  useEffect(() => {
    checkInternet();
  }, [navigator.onLine]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleAnswer = (testId: number, index: number) => {
    let param = `id=${testId}&index=${index}&generateCode=${code}`;
    if (userPosition === "TEACHER") {
      SendTeacherAnswerConfig(param)
        .then((res) => {
          let arr = data;
          let test_index;
          arr.forEach((item, map_index) => {
            if (item.id === res.data.object.id) test_index = map_index + 1;
          });
          if (test_index) arr.splice(test_index - 1, 1, res.data.object);
          setData([...arr]);

          // setEndTime(res.data.object.endTime);
          // setDuration(
          //   (res.data.object.endTime - res.data.object.startTime) / 100
          // );
        })
        .catch((err) => catchError(err.response.data.message));
    } else {
      SendAnswerConfig(param)
        .then((res) => {
          let arr = data;
          let test_index;
          arr.forEach((item, map_index) => {
            if (item.id === res.data.object.id) test_index = map_index + 1;
          });
          if (test_index) arr.splice(test_index - 1, 1, res.data.object);
          setData([...arr]);

          // setEndTime(res.data.object.endTime);
          // setDuration(
          //   (res.data.object.endTime - res.data.object.startTime) / 100
          // );
        })
        .catch((err) => catchError(err.response.data.message));
    }
  };

  const catchError = (text: string) => {
    if (text === "Muddati o'tib ketgan") {
      navigate("/profile/tests");
    } else if (text === "Topilmadi") {
      navigate("/profile/tests");
      message.warning(
        "Maxsus kod xato. Iltimos kodni qayta tekshirib kiriting"
      );
      localStorage.removeItem(USER_CODE);
      setGenerateCode?.(undefined);
    }
  };

  const handleFinishTest = () => {
    if (testId && code) {
      if (userPosition === "TEACHER") {
        FinishTeacherTestConfig(testId, code).then(() => {
          message.success("Test javoblaringiz muvaffaqiyatli saqlandi");
          navigate(`/profile/tests?status=Yakunlangan`);
          localStorage.removeItem(USER_CODE);
          setGenerateCode?.(undefined);
        });
      } else {
        FinishTestConfig(testId, code).then(() => {
          message.success("Test javoblaringiz muvaffaqiyatli saqlandi");
          navigate(`/profile/tests?status=Yakunlangan`);
          localStorage.removeItem(USER_CODE);
          setGenerateCode?.(undefined);
        });
      }
    }
  };

  const makeAnswersCount = () => {
    let count = 0;
    data.forEach((item) => {
      if (typeof item.answerUserIndex === "number") count++;
    });
    return count;
  };

  const getFunc = async () => {
    if (testId && (code || location === "profile")) {
      setTimeout(() => {
        if (userPosition === "TEACHER") {
          GetTeacherQuestionConfig(+testId, code).then((res) => {
            let arr = res.data;
            setData(arr);
            if (arr.length > 0) {
              setEndTime(arr[0].endTime);
              setDuration((arr[0].endTime - arr[0].startTime) / 100);
            } else {
              message.warning("Siz notog'ri kod kiritdingiz");
              navigate("/profile?status=Jarayonda");
              localStorage.removeItem(USER_CODE);
              setGenerateCode?.(undefined);
            }
          });
        } else {
          GetUserQuestionConfig(+testId, code).then((res) => {
            let arr = res.data;
            setData(arr);
            if (arr.length > 0) {
              setEndTime(arr[0].endTime);
              setDuration((arr[0].endTime - arr[0].startTime) / 100);
            } else {
              message.warning("Siz notog'ri kod kiritdingiz");
              navigate("/profile?status=Jarayonda");
              localStorage.removeItem(USER_CODE);
              setGenerateCode?.(undefined);
            }
          });
        }
      }, 100);
    } else if (location === "test") navigate("/profile");
  };

  useEffect(() => {
    getFunc();
  }, []);

  const resultsContent = (
    <div className="test-table-numbers">
      {data.map((item, index) => {
        return (
          <a
            href={`#test${index + 1}`}
            className={`${
              typeof item.answerUserIndex === "number" && "test-table-number"
            }`}
            onClick={onClose}
            key={index + "numbers"}
          >
            {index + 1}
          </a>
        );
      })}
    </div>
  );

  const tableContent = (
    <div className="test-table-content">
      <div className="test-table-close">
        <span onClick={onClose}>
          <CloseSvg />
        </span>
      </div>
      <div className="test-table-header">
        <h2>Test</h2>
        <div className="test-table-header-info">
          <p>
            <span>{makeAnswersCount()}</span>/{data.length}
          </p>
          <div className="test-table-header-info-right">
            <span className="time">
              Vaqt:{" "}
              {endTime && (
                <Statistic.Countdown
                  onChange={(val) =>
                    typeof val === "number" && setTime(val / duration)
                  }
                  format="mm:ss"
                  value={endTime}
                />
              )}
            </span>
            <Progress percent={time} size="small" showInfo={false} />
          </div>
        </div>
      </div>
      {resultsContent}
      <div onClick={() => setVisible(true)} className="test-table-footer">
        Yakunlash
      </div>
    </div>
  );

  return (
    <>
      {location === "test" ? (
        <>
          <div className="test">
            <div className="test-content container">
              <Button
                onClick={showDrawer}
                className="test-button"
                type="primary"
                size="large"
              >
                Batafsil
              </Button>
              <div className="test-container">
                <h2>{theme}</h2>
                {data.map((item: any, index: any) => (
                  <Test
                    data={item}
                    index={index}
                    location="test"
                    key={index + "key"}
                    handleAnswer={handleAnswer}
                  />
                ))}
                <div
                  onClick={() => setVisible(true)}
                  className="test-table-footer test-container-footer"
                >
                  Yakunlash
                </div>
              </div>
            </div>
            <div className="test-table">{tableContent}</div>
            <Drawer
              className="test-drawer"
              title=""
              placement="right"
              onClose={onClose}
              open={open}
            >
              {tableContent}
            </Drawer>

            {/* Finish test modal */}
            <Modal
              title=""
              width={350}
              open={visible}
              okText="Ha"
              cancelText="Bekor qilish"
              onOk={handleFinishTest}
              onCancel={() => setVisible(false)}
              className="no-close-button"
            >
              <h2 style={{ textAlign: "center" }} className="no-data-p">
                Testni yakunlashni tasdiqlaysizmi?
              </h2>
              {resultsContent}
              <br />
            </Modal>
          </div>{" "}
        </>
      ) : (
        <>
          <Button
            icon={<ArrowLeftOutlined />}
            style={{ marginBottom: 16 }}
            onClick={() => navigate("/profile/tests?status=Yakunlangan")}
          >
            Orqaga
          </Button>
          <div className="test profile__test">
            <div className="test-container">
              {data.map((item: any, index: any) => (
                <Test
                  data={item}
                  index={index}
                  location="profile"
                  key={index + "key"}
                  handleAnswer={() => {}}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TestPage;
