import { FacebookSVG, InstagramSvg, TelegramSVG } from "../../assets/svg";
import { Link } from "react-router-dom";
import { Image } from "antd";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__nav">
          <div className="footer__info">
            <Link to="/" className="flex logo">
              <>
                <img
                  src={require("src/assets/images/vazirliklogooq.png")}
                  alt="author"
                />
                <span>{process.env.REACT_APP_TITLE}</span>
              </>
            </Link>
            <p className="info">
              Portalda eʼlon qilingan materiallardan nusxa koʻchirish, tarqatish
              va boshqa shakllarda foydalanish faqat tahririyat yozma roziligi
              bilan amalga oshirilishi mumkin.
            </p>
          </div>
          <div className="footer__socials">
            <h4>Biz ijtimoiy tarmoqlarda</h4>
            <a
              href="https://www.instagram.com/edu.uz/"
              target={"_blank"}
              rel="noreferrer"
              className="social"
            >
              <InstagramSvg />
              <span> Instagram</span>
            </a>
            <a
              href="https://t.me/my_edu_uz"
              target={"_blank"}
              rel="noreferrer"
              className="social"
            >
              <TelegramSVG />
              <span> Telegram</span>
            </a>
            <a
              href="https://facebook.com/eduuzrasmiy"
              target={"_blank"}
              className="social"
              rel="noreferrer"
            >
              <FacebookSVG />
              <span> Facebook</span>
            </a>
          </div>
          <div className="footer__contact">
            <h4>Biz bilan aloqa</h4>
            <a href="tel:1006" style={{ color: "white" }} className="contact">
              <span>Ishonch telefoni: 1006</span>
            </a>
            {/* <a
              href="tel:+998712306464"
              style={{ color: "white" }}
              className="contact"
            >
              <PhoneSVG />
              <span>+998712306464</span>
            </a> */}
             <a
              href="https://t.me/survey_helpbot"
              target={"_blank"}
              rel="noreferrer"
              style={{ color: "white" }}
              className="contact"
            >
              {/*<TelegramSVG />*/}
              <span>Murojaat uchun: @survey_helpbot</span>
            </a>
          </div>
        </div>
        <p>2023 © Raqamli ta'lim texnologiyalarini rivojlantirish markazi</p>
      </div>
    </div>
  );
}

export default Footer;
