import { LoginTypes } from "src/static/interface";
import { CreateFunc, GetFunc } from "../config/open";

//////////// Auth controller ////////////
export const SignInConfig = (code: string) => {
  return CreateFunc(`/auth/signIn?code=${code}`, "");
};
export const SignInHemisConfig = (data: LoginTypes) => {
  return CreateFunc(`/auth/signInHemis`, data);
};
export const SignInTeacherConfig = (data: LoginTypes) => {
  return CreateFunc(`/auth/signInTeacher`, data);
};
export const SignInAdminConfig = (data: LoginTypes) => {
  return CreateFunc(`/auth/signInAdmin`, data);
};

//////////// Address controller ////////////
export const GetUniversities = () => {
  return GetFunc(`/auth/universities`);
};
export const GetSpecialities = (id: number) => {
  return GetFunc(`/auth/speciality/${id}`);
};
export const GetLanguages = () => {
  return GetFunc(`/auth/language`);
};
export const GetFacultiesConfig = (id: number) => {
  return GetFunc(`/auth/faculty/${id}`);
};
export const GetFacultyByUniversityConfig = (id: number, id2: number) => {
  return GetFunc(`/auth/facultyId/${id}/universityId/${id2}`);
};
