import xlsx from "json-as-xlsx";

//Make excel data
export const generateExcel = (content, theme) => {
  let arr = [];
  content.forEach((item, index) => {
    arr.push({
      ...item,
      number: String(index + 1),
    });
  });
  let excelBase = [
    {
      sheet: theme,
      columns: [
        { label: "№", value: "key" },
        { label: "FISh", value: "fullName" },
        { label: "Maxsus kod", value: "accessCode" },
        { label: "JSHSHIR", value: "pinfl" },
      ],
      content: arr,
    },
  ];

  let settings = {
    fileName: "Test to'plami foydalanuvchilari", // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
  };

  xlsx(excelBase, settings); // Will download the excel file
};
