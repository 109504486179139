import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Button, Breadcrumb, Input, message, Select } from "antd";
import {
  CreateBlockConfig,
  GetBlockByIdConfig,
  UpdateBlockConfig,
} from "src/service/api";
import { adminHomeBcmb } from "src/static/data/breadcrumb_items";
import { BlockResTypes, BlockTypes, LanguageTypes } from "src/static/interface";
import { rules } from "src/static/rules";
import { GetLanguages } from "src/service/api/open";

function BlockActionsPage() {
  const navigate = useNavigate();
  const { Option } = Select;
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [lanuages, setLanugages] = useState<LanguageTypes[]>([]);
  const blockId = searchParams.get("blockId");

  const getFunc = () => {
    GetLanguages().then((res) => {
      setLanugages(res.data);
    });
    if (blockId) {
      GetBlockByIdConfig(blockId).then((res: { data: BlockResTypes }) => {
        form.setFieldsValue({
          blockName: res.data.name,
          languageId: res.data.languageId,
        });
      });
    }
  };

  useEffect(() => {
    getFunc();
  }, []);

  const handleSubmit = (val: BlockTypes) => {
    const { blockName } = val;
    const { languageId } = val;
    setLoading(true);
    let param = `blockName=${blockName}&languageId=${languageId}`;
    if (blockId) {
      UpdateBlockConfig(blockId, param)
        .then(() => {
          message.success("Block ma'lumotlari muvaffiyaqatli tahrirlandi");
          navigate("/admin/block");
        })
        .finally(() => setLoading(false));
    } else {
      CreateBlockConfig(param)
        .then(() => {
          message.success("Block muvaffiyaqatli qo'shildi");
          navigate("/admin/block");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div>
      <Breadcrumb
        items={[
          adminHomeBcmb,
          {
            title: (
              <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                Bloklar
              </div>
            ),
          },
          {
            title: blockId ? "Blokni tahrirlash" : "Blok qo'shish",
          },
        ]}
      />
      <div className="layout-detail">
        <div className="layout-detail-header">
          {blockId
            ? "Test blogi ma'lumotlarini tahrirlash"
            : "Test blogi ma'lumotlarini qo'shish"}
        </div>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="blockName" label="Blok nomi" rules={[rules.block]}>
            <Input allowClear size="large" placeholder={rules.block.message} />
          </Form.Item>

          <Form.Item
            name="languageId"
            label="Blok tili"
            rules={[rules.block_lan]}
          >
            <Select
              allowClear
              size="large"
              placeholder={rules.block_lan.message}
            >
              {lanuages.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className="modal-footer">
            <Button htmlType="submit" type="primary" loading={loading}>
              Saqlash
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default BlockActionsPage;
