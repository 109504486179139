import { Alert } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment-timezone";
import {
  BlockResTypes,
  CollectionResTypes,
  CollectionUsersTypes,
  FacultyTableTypes,
  FacultyTypes,
  QuizResTypes,
  QuizRuleResTypes,
  SpecialityResTypes,
  SpecialityTableTypes,
  SpecialityTypes,
  TestResTypes,
  UniversityTypes,
} from "src/static/interface";

export const testAdminColumns: ColumnsType<TestResTypes> = [
  {
    title: "№",
    dataIndex: "key",
    key: "key",
    width: 60,
  },
  {
    title: "Blogi",
    key: "blockName",
    dataIndex: "blockName",
  },
  {
    title: "Darajasi",
    key: "level",
    dataIndex: "level",
  },
  {
    title: "Savoli",
    key: "question",
    dataIndex: "question",
    render: (question) => (
      <div
        dangerouslySetInnerHTML={{ __html: question }}
        className="line-clamp3"
      />
    ),
  },
];

export const blockColumns: ColumnsType<BlockResTypes> = [
  {
    title: "№",
    dataIndex: "key",
    key: "key",
    width: 60,
  },
  {
    title: "Nomi",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Tili",
    key: "languageName",
    dataIndex: "languageName",
  },
];

export const themeColumns: ColumnsType<BlockResTypes> = [
  {
    title: "№",
    dataIndex: "key",
    key: "key",
    width: 60,
  },
  {
    title: "Nomi",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Tili",
    key: "language",
    dataIndex: "language",
  },
];

export const quizCollectionColumns: ColumnsType<QuizRuleResTypes> = [
  {
    title: "№",
    dataIndex: "key",
    key: "key",
    width: 60,
  },
  {
    title: "Mavzusi",
    key: "theme",
    dataIndex: "theme",
  },
  {
    title: "Tugash vaqti",
    key: "endTime",
    dataIndex: "endTime",
    render: (endTime) => String(moment(endTime).format("YYYY-MM-DD kk-mm")),
  },
  {
    title: "Holati",
    key: "active",
    dataIndex: "active",
    render: (active) => (
      <Alert
        message={active ? "Aktiv" : "Passiv"}
        type={active ? "success" : "warning"}
        style={{ width: 65, padding: "1px 12px" }}
      />
    ),
  },
];

export const quizColumns: ColumnsType<QuizResTypes> = [
  {
    title: "№",
    dataIndex: "key",
    key: "key",
    width: 60,
  },
  {
    title: "Mavzusi",
    key: "theme",
    dataIndex: "theme",
  },
  {
    title: "Savoli",
    key: "quiz",
    dataIndex: "quiz",
    render: (quiz) => (
      <div
        dangerouslySetInnerHTML={{ __html: quiz }}
        className="line-clamp3"
      ></div>
    ),
  },
];

export const collectionColumns: ColumnsType<CollectionResTypes> = [
  {
    title: "№",
    dataIndex: "key",
    key: "key",
    width: 60,
  },
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    width: 60,
  },
  {
    title: "Mavzusi",
    key: "theme",
    dataIndex: "theme",
    width: 300,
  },
  {
    title: "Till",
    key: "languageName",
    dataIndex: "languageName",
    width: 100,
  },
  {
    title: "Blok",
    key: "blockName",
    dataIndex: "blockName",
    width: 300,
  },
];

export const collectionUserColumns: ColumnsType<CollectionUsersTypes> = [
  {
    title: "№",
    dataIndex: "key",
    key: "key",
    width: 60,
  },
  {
    title: "FISh",
    key: "fullName",
    dataIndex: "fullName",
  },
  {
    title: "Maxsus kod",
    key: "accessCode",
    dataIndex: "accessCode",
  },
  {
    title: "JSHSHIR",
    key: "pinfl",
    dataIndex: "pinfl",
  },
];

export const specialityColumns: ColumnsType<SpecialityTableTypes> = [
  {
    title: "№",
    dataIndex: "key",
    key: "key",
    width: 60,
  },
  {
    title: "Universitet",
    key: "universityName",
    dataIndex: "universityName",
  },
  {
    title: "Mutaxasisliklar",
    key: "specialityResponses",
    dataIndex: "specialityResponses",
    render: (specialityResponses) => (
      <ul style={{ listStyle: "initial" }}>
        {specialityResponses.map((item: SpecialityTypes) => {
          return (
            <li key={item.specialityCode} style={{ listStyleType: "initial" }}>
              {item.specialityCode} - {item.specialityName}
            </li>
          );
        })}
      </ul>
    ),
  },
];

export const facultyColumns: ColumnsType<FacultyTableTypes> = [
  {
    title: "№",
    dataIndex: "key",
    key: "key",
    width: 60,
  },
  {
    title: "Universitet",
    key: "universityName",
    dataIndex: "universityName",
  },
  {
    title: "Fakultetlar",
    key: "specialityResponses",
    dataIndex: "specialityResponses",
    render: (specialityResponses) => (
      <ul style={{ listStyle: "initial" }}>
        {specialityResponses.map((item: FacultyTypes) => {
          return (
            <li key={item.facultyCode} style={{ listStyleType: "initial" }}>
              {item.facultyCode} - {item.facultyName}
            </li>
          );
        })}
      </ul>
    ),
  },
];

export const universityColumns: ColumnsType<UniversityTypes> = [
  {
    title: "№",
    dataIndex: "key",
    key: "key",
    width: 60,
  },
  {
    title: "Universitet",
    key: "name",
    dataIndex: "name",
  },
];

export const ratingColumns = [
  {
    title: "O'rni",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "Nom",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Tel",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Viloyat",
    dataIndex: "region",
    key: "region",
  },
  {
    title: "Fan",
    dataIndex: "subject",
    key: "subject",
  },
  {
    title: "Sarflagan vaqti",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "To'plagan bali",
    dataIndex: "total",
    key: "total",
  },
];
