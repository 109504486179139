import { Alert, Button, Modal, Table, message } from "antd";
import { useEffect, useState } from "react";
import {
  CollectionResTypes,
  FacultyTableTypes,
  FacultyTypes,
  SpecialityResTypes,
  SpecialityTableTypes,
  SpecialityTypes,
} from "src/static/interface";
import {
  ChangeCollectionStatusConfig,
  ChangeTeacherCollectionStatusConfig,
  GetCollectionsConfig,
  GetTeacherCollectionsConfig,
} from "src/service/api";
import type { ColumnsType } from "antd/es/table";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { collectionColumns } from "src/static/data/columns";
import {
  EditOutlined,
  PlusOutlined,
  EyeOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import PaginationFilter from "src/components/filter/PaginationFilter";
import SearchFilter from "src/components/filter/SearchFilter";
import UniversityFilter from "src/components/filter/UniversityFilter";

function CollectionPage({ user = "student" }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<CollectionResTypes[]>([]);
  // const [dataSource2, setDataSource2] = useState<SpecialityTableTypes[]>([]);
  // const [dataSource3, setDataSource3] = useState<FacultyTableTypes[]>([]);
  // const [theme, setTheme] = useState("");
  // const [visible, setVisible] = useState(false);

  const openModal = (arr: SpecialityResTypes[]) => {
    // setVisible(true);
    // let arr = dataSource.find((el) => el.id === id)?.specialityResponses;
    // let them = dataSource.find((el) => el.id === id)?.theme;
    // them && setTheme(them);
    if (arr) {
      if (user === "student") {
        let arr2: SpecialityTableTypes[] = [];
        arr.forEach((item) => {
          let obj = arr2.find((el) => el.universityId === item.universityId);
          let obj2 = {
            id: item.specialityId,
            specialityCode: item.specialityСode,
            specialityName: item.specialityName,
          };
          if (obj) {
            obj.specialityResponses.push(obj2);
          } else {
            arr2.push({
              universityId: item.universityId,
              universityName: item.universityName,
              specialityResponses: [obj2],
            });
          }
        });
        arr2.forEach((item, index) => {
          item.key = index + 1;
        });
        return arr2;
        // setDataSource2(arr2);
      } else if (user === "teacher") {
        let arr2: FacultyTableTypes[] = [];
        arr.forEach((item) => {
          let obj = arr2.find((el) => el.universityId === item.universityId);
          let obj2 = {
            facultyId: item.facultyId,
            facultyCode: item.facultyCode,
            facultyName: item.facultyName,
          };
          if (obj) {
            obj.specialityResponses.push(obj2);
          } else {
            arr2.push({
              universityId: item.universityId,
              universityName: item.universityName,
              specialityResponses: [obj2],
            });
          }
        });
        arr2.forEach((item, index) => {
          item.key = index + 1;
        });
        return arr2;
        // setDataSource3(arr2);
      }
    }
  };

  // const closeModal = () => {
  //   setVisible(false);
  // };

  const handleMakeUrl = () => {
    let url = `?`;
    for (let key of Array.from(searchParams.keys())) {
      let value = searchParams.get(key);
      url = url + `&${key}=${value}`;
    }
    return url === "?" ? "" : url;
  };

  const handleChangeStatus = (id: number) => {
    let obj = dataSource.find((el) => el.id === id);
    Modal.confirm({
      title: `${obj?.theme} mavzusidagi shakllangan test holatini o'zgartirishni tasdiqlaysizmi? `,
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      cancelText: "Bekor qilish",
      content: obj?.active
        ? "Testlarni passiv holatga o'tkazish orqali, test natijalari e'lon qilinadi."
        : "",
      onOk() {
        if (user === "teacher")
          ChangeTeacherCollectionStatusConfig(id, !obj?.active).then(() => {
            message.success("Testlar holati muvaffaqiyatli o'zgartirildi");
            getFunc();
          });
        else
          ChangeCollectionStatusConfig(id, !obj?.active).then(() => {
            message.success("Testlar holati muvaffaqiyatli o'zgartirildi");
            getFunc();
          });
      },
    });
  };

  const getFunc = () => {
    setLoading(true);
    let url = handleMakeUrl();
    if (user === "teacher")
      GetTeacherCollectionsConfig(url)
        .then((res) => makeData(res.data))
        .finally(() => setLoading(false));
    else
      GetCollectionsConfig(url)
        .then((res) => makeData(res.data))
        .finally(() => setLoading(false));
  };

  const makeData = (data: {
    content: CollectionResTypes[];
    totalElements: number;
  }) => {
    const arr = data.content;
    let size = searchParams.get("size");
    let page = searchParams.get("page");
    arr.forEach((item, index) => {
      let univerArr = openModal(item.specialityResponses);
      item.key = page && size ? index + 1 + (+page - 1) * +size : index + 1;
      if (univerArr) item.universityResponces = univerArr;
    });
    setDataSource(arr);
    setTotal(data.totalElements);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getFunc();
  }, [searchParams, location.pathname]);

  const commonColumns: ColumnsType<CollectionResTypes> = [
    {
      title: "Test vaqti",
      key: "time",
      dataIndex: "time",
      width: 70,
    },
    {
      title: "Testlar soni",
      key: "testMaxCount",
      dataIndex: "testMaxCount",
      width: 80,
    },
    {
      title: "Holati",
      key: "id",
      dataIndex: "id",
      width: 90,
      render: (id) => (
        <Alert
          message={
            dataSource.find((el) => el.id === id)?.active ? "Aktiv" : "Passiv"
          }
          type={
            dataSource.find((el) => el.id === id)?.active
              ? "success"
              : "warning"
          }
          style={{ width: 65, padding: "1px 12px", cursor: "pointer" }}
          onClick={() => handleChangeStatus(id)}
        />
      ),
    },
    // {
    //   title: "Universitetlar",
    //   dataIndex: "id",
    //   key: "id",
    //   width: 100,
    //   render: (id) => (
    //     <Button type="primary" onClick={() => openModal(id)}>
    //       Batafsil
    //     </Button>
    //   ),
    // },
    {
      title: "Foydalanuvchilar",
      dataIndex: "id",
      key: "id",
      width: 140,
      render: (id) => (
        <Link
          to={
            user === "teacher"
              ? `users?collId=${id}`
              : `collection/users?collId=${id}`
          }
        >
          <Button icon={<EyeOutlined />} type="link">
            Ko'rish
          </Button>
        </Link>
      ),
    },
    {
      title: "Amallar",
      dataIndex: "id",
      key: "id",
      width: 100,
      fixed: "right",
      render: (id) => (
        <div className="layout-content-table-buttons">
          <EditOutlined
            onClick={() => {
              navigate(
                user === "teacher"
                  ? `actions?collId=${id}`
                  : `collection/actions?collId=${id}`
              );
            }}
          />
        </div>
      ),
    },
  ];

  const userColumns: ColumnsType<CollectionResTypes> = [
    ...collectionColumns,
    {
      title: "Universitetlar",
      dataIndex: "universityResponces",
      key: "universityResponces",
      width: 300,
      render: (universityResponces) => (
        <ul>
          {universityResponces?.map((item: any) => {
            return (
              <li key={item.key}>
                {item.universityName}
                <ul style={{ paddingLeft: 18 }}>
                  {user === "student" &&
                    item.specialityResponses?.map((item: SpecialityTypes) => {
                      return (
                        <li
                          key={item.specialityId}
                          style={{ listStyleType: "disc" }}
                        >
                          {item.specialityCode} - {item.specialityName}
                        </li>
                      );
                    })}
                  {user === "teacher" &&
                    item.specialityResponses?.map((item: FacultyTypes) => {
                      return (
                        <li
                          key={item.facultyId}
                          style={{ listStyleType: "disc" }}
                        >
                          {item.facultyCode} - {item.facultyName}
                        </li>
                      );
                    })}
                </ul>
              </li>
            );
          })}
        </ul>
      ),
    },
    ...commonColumns,
  ];

  const teacherColumns: ColumnsType<CollectionResTypes> = [
    ...collectionColumns,
    ...commonColumns,
  ];

  return (
    <div>
      <div className="layout-content-header">
        <p>
          {user === "teacher"
            ? "O'qituvchilar uchun test shakllantirish"
            : "Talabalar uchun test shakllantirish"}
        </p>
        <div className="right">
          <SearchFilter />
          <UniversityFilter />
          <Link to={user === "teacher" ? "actions" : "collection/actions"}>
            <Button icon={<PlusOutlined />} type="primary">
              Qo'shish
            </Button>
          </Link>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={user === "teacher" ? teacherColumns : userColumns}
        pagination={false}
        loading={loading}
        scroll={{ x: 1500 }}
      />
      <PaginationFilter total={total} />
      <div className="layout-content-total">
        Jami: <span>{total}</span>
      </div>

      {/* Universities modal */}
      {/* <Modal
        open={visible}
        title={`${theme} mavzusidagi testlar uchun biriktirilgan universitetlar`}
        footer=""
        width={900}
        onCancel={closeModal}
      >
        <br />
        {dataSource2.length > 0 && user === "student" && (
          <Table
            dataSource={dataSource2}
            columns={specialityColumns}
            pagination={dataSource2.length > 10 ? { pageSize: 10 } : false}
          />
        )}
        {dataSource3.length > 0 && user === "teacher" && (
          <Table
            dataSource={dataSource3}
            columns={facultyColumns}
            pagination={dataSource3.length > 10 ? { pageSize: 10 } : false}
          />
        )}
      </Modal> */}
    </div>
  );
}

export default CollectionPage;
