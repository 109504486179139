import React, { useState } from "react";
import { IUser, ContextTypes } from "src/static/interface";
import { useSearchParams } from "react-router-dom";

export const MainContext = React.createContext<ContextTypes>({
  loginOpen: false,
  profileOpen: false,
});

function MainContextProvider({ children }: { children?: React.ReactNode }) {
  const [testStartModal, setTestStartModal] = useState(false);
  const [testPackageId, setTestPackageId] = useState(0);
  const [endTime, setEndTime] = useState(0);

  // Search bar maker
  const [searchParams, setSearchParams] = useSearchParams();
  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  //Set generate code
  const [generateCode, setGenerateCode] = useState<number | undefined>();

  //Login modal visible
  const [loginOpen, setLoginOpen] = useState(false);

  //Profile dropdown visible
  const [profileOpen, setProfileOpen] = useState(false);

  // Set user phone
  const [userPhone, setUserPhone] = useState<string>("");

  // Set user info
  const [userInfo, setUserInfo] = useState<IUser>();

  return (
    <MainContext.Provider
      value={{
        handleMakeParams,
        testStartModal,
        setTestStartModal,
        testPackageId,
        setTestPackageId,
        endTime,
        setEndTime,
        userPhone,
        setUserPhone,
        userInfo,
        setUserInfo,
        loginOpen,
        setLoginOpen,
        profileOpen,
        setProfileOpen,
        generateCode,
        setGenerateCode,
      }}
    >
      {children}
    </MainContext.Provider>
  );
}

export default MainContextProvider;
