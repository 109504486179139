import { TestPageProps } from "src/static/interface";
import { EmtpyRoundSvg, FullRoundSvg } from "../../assets/svg";

function Test({ data, index, handleAnswer, location }: TestPageProps) {
  const checkAnswer = (order: number, answer: number, tanswer: number) => {
    if (location === "test" && answer === order) {
      return "test-box-active";
    } else if (location === "profile") {
      if (answer === tanswer && answer === order) return "test-box-active";
      else if (tanswer !== order && answer === order)
        return "test-box-incorrect";
    }
  };
  return (
    <div
      className="test-box"
      key={`${index} + "testbox"}`}
      id={"test" + (index + 1)}
    >
      <div className="flex">
        <h2>{index + 1}</h2>
        <div dangerouslySetInnerHTML={{ __html: data.question }} />
      </div>
      <div className="test-box-variants">
        {/* a */}
        <a
          href={`#test${index + 2}`}
          className={checkAnswer(0, data.answerUserIndex, data.answerIndex)}
          onClick={() => handleAnswer(data.id, 0)}
        >
          {data.answerUserIndex === 0 ? <FullRoundSvg /> : <EmtpyRoundSvg />}
          <div
            dangerouslySetInnerHTML={{ __html: data.variants[0] }}
            className={`${
              location === "profile" &&
              data.answerIndex === 0 &&
              "test-box-correct"
            }`}
          ></div>
          {location === "profile" && data.answerIndex === 0 && (
            <img
              src={require("src/assets/images/tick.png")}
              className="correct-answer"
              alt="correct1"
            />
          )}
        </a>

        {/* b */}
        <a
          href={`#test${index + 2}`}
          className={checkAnswer(1, data.answerUserIndex, data.answerIndex)}
          onClick={() => handleAnswer(data.id, 1)}
        >
          {data.answerUserIndex === 1 ? <FullRoundSvg /> : <EmtpyRoundSvg />}
          <div
            dangerouslySetInnerHTML={{ __html: data.variants[1] }}
            className={`${
              location === "profile" &&
              data.answerIndex === 1 &&
              "test-box-correct"
            }`}
          ></div>
          {location === "profile" && data.answerIndex === 1 && (
            <img
              src={require("src/assets/images/tick.png")}
              className="correct-answer"
              alt="correct2"
            />
          )}
        </a>

        {/* c */}
        <a
          href={`#test${index + 2}`}
          className={checkAnswer(2, data.answerUserIndex, data.answerIndex)}
          onClick={() => handleAnswer(data.id, 2)}
        >
          {data.answerUserIndex === 2 ? <FullRoundSvg /> : <EmtpyRoundSvg />}
          <div
            dangerouslySetInnerHTML={{ __html: data.variants[2] }}
            className={`${
              location === "profile" &&
              data.answerIndex === 2 &&
              "test-box-correct"
            }`}
          ></div>
          {location === "profile" && data.answerIndex === 2 && (
            <img
              src={require("src/assets/images/tick.png")}
              className="correct-answer"
              alt="correct3"
            />
          )}
        </a>

        {/* d */}
        <a
          href={`#test${index + 2}`}
          className={checkAnswer(3, data.answerUserIndex, data.answerIndex)}
          onClick={() => handleAnswer(data.id, 3)}
        >
          {data.answerUserIndex === 3 ? <FullRoundSvg /> : <EmtpyRoundSvg />}
          <div
            dangerouslySetInnerHTML={{ __html: data.variants[3] }}
            className={`${
              location === "profile" &&
              data.answerIndex === 3 &&
              "test-box-correct"
            }`}
          ></div>
          {location === "profile" && data.answerIndex === 3 && (
            <img
              src={require("src/assets/images/tick.png")}
              className="correct-answer"
              alt="correct4"
            />
          )}
        </a>
      </div>
    </div>
  );
}

export default Test;
