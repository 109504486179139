import { rules } from "src/static/rules";
export const testAddItems = [
  {
    label: "Test savoli",
    name: "question",
    rules: [rules.question],
  },
  {
    label: "1-variant (To'g'ri javobni shu yerga kiriting)",
    name: "variant1",
    rules: [rules.question],
  },
  {
    label: "2-variant",
    name: "variant2",
    rules: [rules.question],
  },
  {
    label: "3-variant",
    name: "variant3",
    rules: [rules.question],
  },
  {
    label: "4-variant",
    name: "variant4",
    rules: [rules.question],
  },
];

export const collectionItems = [
  {
    label: "Test vaqti (minutlarda)",
    name: "time",
    rules: [rules.time],
    message: rules.time.message,
  },
  {
    label: "1-qiyinlikdagi testlar soni",
    name: "testLevel1",
    rules: [rules.level1],
    message: rules.level1.message,
  },
  {
    label: "2-qiyinlikdagi testlar soni",
    name: "testLevel2",
    rules: [rules.level2],
    message: rules.level2.message,
  },
  {
    label: "3-qiyinlikdagi testlar soni",
    name: "testLevel3",
    rules: [rules.level3],
    message: rules.level3.message,
  },
];
