import { BrowserRouter as Router } from "react-router-dom";
import RoutElements from "./router/routes";
import MainContextProvider from "./context";

function App() {
  return (
    <Router>
      <MainContextProvider>
        <RoutElements />
      </MainContextProvider>
    </Router>
  );
}

export default App;
