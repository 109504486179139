import { useLocation, useNavigate } from "react-router-dom";
import { ProfileButtonProps } from "src/static/interface";

function ProfileButton({ link, svg, title }: ProfileButtonProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const keys = ["/profile/informs", "/profile/quizes"];
  let active =
    link === "/profile"
      ? !keys.includes(location.pathname)
      : location.pathname === link;

  if (location.pathname === "/profile/quizes/result") {
    if (link === "/profile/quizes") active = true;
    else active = false;
  }

  return (
    <button
      className={`profile__tab flex ${active && "profile__tab-active"}`}
      onClick={() => navigate(link)}
    >
      {svg}
      <span>{title}</span>
    </button>
  );
}

export default ProfileButton;
