import React, { useState } from "react";
import { Dropdown, Layout, Menu, Modal } from "antd";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { handleLogoutFunc } from "src/utils";
import { LogoutSvg } from "src/assets/svg";
import type { MenuProps } from "antd";
import { isAdmin } from "src/static/const";
import { QuestionCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

const AdminPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const detailPath = location.pathname.split("/")[3];

  const singOutFunc = () => {
    Modal.confirm({
      title: "Tizimdan chiqmoqchimisz",
      okText: "Ha",
      cancelText: "Bekor qilish",
      width: 330,
      maskClosable: true,
      onOk: () => {
        handleLogoutFunc();
      },
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "logout",
      label: (
        <p onClick={singOutFunc} className="layout-button">
          Chiqish <LogoutSvg />
        </p>
      ),
    },
  ];

  const tableItems: MenuProps["items"] = [
    {
      label: "Testlar",
      icon: <QuestionCircleOutlined />,
      key: "sub1",
      children: [
        {
          label: "Talabalar uchun test shakllantirish",
          key: "/admin",
          onClick: () => navigate("/admin"),
        },
        {
          label: "O'qituvchilar uchun test shakllantirish",
          key: "/admin/teacher",
          onClick: () => navigate("/admin/teacher"),
        },
        {
          label: "Testlar",
          key: "/admin/test",
          onClick: () => navigate("/admin/test"),
        },
        {
          label: "Bloklar",
          key: "/admin/block",
          onClick: () => navigate("/admin/block"),
        },
      ],
    },
    {
      label: "Talabalar uchun so'rovnomalar",
      icon: <CheckCircleOutlined />,
      key: "sub2",
      children: [
        {
          label: "So'rovnoma shakllantirish",
          key: "/admin/quiz_collection",
          onClick: () => navigate("/admin/quiz_collection"),
        },
        {
          label: "So'rovnomalar",
          key: "/admin/quiz",
          onClick: () => navigate("/admin/quiz"),
        },
        {
          label: "Mavzular",
          key: "/admin/theme",
          onClick: () => navigate("/admin/theme"),
        },
      ],
    },
  ];

  return (
    <Layout className="layout">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className="layout-sider"
        width={300}
      >
        <Link to="/">
          <img
            src={require("src/assets/images/logo.png")}
            alt="logo"
            width={40}
            height={40}
          />
          {!collapsed && `${process.env.REACT_APP_TITLE}`}
        </Link>
        {isAdmin && (
          <Menu
            theme="dark"
            mode="inline"
            items={tableItems}
            defaultActiveFirst
            defaultOpenKeys={["sub2", "sub1"]}
            selectedKeys={[location.pathname]}
          />
        )}
      </Sider>
      <Layout className="site-layout">
        <Header className="layout-header">
          <h3>Vazirlik admin</h3>
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="bottomRight"
          >
            <img
              src={require("src/assets/images/admin.png")}
              alt="error img"
              className="layout-button-img"
            />
          </Dropdown>
        </Header>
        <Content className="layout-content">
          <div
            className={`${
              detailPath === "add"
                ? "layout-content-default"
                : "layout-content-pages"
            }`}
          >
            <Outlet />
          </div>
          <Footer className="layout-footer">
            <span>@Ishlab chiqaruvchi:</span>
            <a
              href="https://t.me/elektron_talim_markazi"
              target="_blank"
              rel="noreferrer"
            >
              Ta'lim muassasalarida elektron ta'limni joriy etish markazi
            </a>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminPage;
