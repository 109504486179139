import { Button, Form, Input, Modal, Select, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "src/context";
import {
  GetUniversities,
  SignInHemisConfig,
  SignInTeacherConfig,
} from "src/service/api/open";
import { loginTabItems } from "src/static/data/tab_items";
import { LoginTypes, UniversityTypes } from "src/static/interface";
import { rules } from "src/static/rules";
import { MaskedInput } from "antd-mask-input";
import { catchFunc, handleLoginFunc } from "src/utils";
import { ROLE_U, USER_POSITION } from "src/static/const";

function Login() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { loginOpen, setLoginOpen } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [universities, setUniversities] = useState<UniversityTypes[]>([]);
  const [key, setKey] = useState("1");

  const openModal = () => {
    setLoginOpen?.(true);
  };
  const closeModal = () => {
    setLoginOpen?.(false);
    form.resetFields();
  };
  const handleSubmit = (val: LoginTypes) => {
    setLoading(true);
    val.login = val.login.split(" ").join("");
    if (key === "1")
      SignInHemisConfig(val)
        .then((res) => {
          localStorage.removeItem(USER_POSITION);
          handleLoginFunc(res.data.object);
        })
        .catch((err) => catchFunc(err))
        .finally(() => setLoading(false));
    else if (key === "2")
      SignInTeacherConfig(val)
        .then((res) => {
          localStorage.setItem(USER_POSITION, "TEACHER");
          handleLoginFunc(res.data.object, ROLE_U);
        })
        .catch((err) => catchFunc(err))
        .finally(() => setLoading(false));
  };
  const getFunc = () => {
    if (universities.length === 0)
      GetUniversities().then((res) => {
        setUniversities(res.data);
      });
  };
  useEffect(() => {
    getFunc();
  }, []);

  //Tab section
  const handleChange = (key: string) => {
    setKey(key);
  };

  //Tab section
  const handleSelect = (code: number) => {
    if (key === "1") form.setFieldsValue({ login: code });
  };
  return (
    <>
      <button className="kirish" onClick={openModal}>
        Kirish
      </button>

      {/* Login modal */}
      <Modal
        open={loginOpen}
        title=""
        footer=""
        width={500}
        onCancel={closeModal}
        className="login-modal"
      >
        <h2 className="login-main-title">Hemis orqali kirish</h2>
        <Tabs activeKey={key} items={loginTabItems} onChange={handleChange} />
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Form.Item
            name="universityCode"
            label="Universitet"
            rules={[rules.university]}
          >
            <Select
              size="large"
              allowClear
              placeholder={rules.university.message}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onChange={handleSelect}
            >
              {universities.map((item) => (
                <Option key={item.id} value={item.code}>
                  {item.code + " - " + item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {key === "1" ? (
            <Form.Item
              label="Hemis login"
              name="login"
              rules={[rules.hemis_login, rules.hemis_pattern]}
            >
              <MaskedInput
                mask={"000 000 000 000"}
                size="large"
                placeholder={rules.hemis_login.message}
              />
            </Form.Item>
          ) : (
            <Form.Item
              label="Hemis login"
              name="login"
              rules={[rules.hemis_login]}
            >
              <Input size="large" placeholder={rules.hemis_login.message} />
            </Form.Item>
          )}

          <Form.Item
            label="Hemis parol"
            name="password"
            rules={[rules.hemis_password]}
          >
            <Input.Password
              placeholder={rules.hemis_password.message}
              type="text"
              size="large"
            />
          </Form.Item>

          <Button
            type="primary"
            size="large"
            className="full-width login-main-button"
            htmlType="submit"
            loading={loading}
          >
            Kirish
          </Button>

          <div className="login-main-or">
            <span></span> yoki <span></span>
          </div>

          <a href="https://survey.hemis.uz/api/auth/oneId">
            <Button
              type="primary"
              size="large"
              className="login-main-button-oneid"
            >
              ONE ID orqali
            </Button>
          </a>
        </Form>
      </Modal>
    </>
  );
}

export default Login;
