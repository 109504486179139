import { useContext } from "react";
import { MainContext } from "src/context";
// import { userPosition } from "src/static/const";

function ProfileInfo() {
  const { userInfo } = useContext(MainContext);

  const user = localStorage.getItem('TEST_EDU_USER_POSITION') === 'TEACHER' ? "Professor-o'qituvchi": 'Talaba'

  return (
    <div className="profile__info">
      {/* <h2>Shaxsiy ma'lumotlar</h2>
      <p>
        FISh: <span>{userInfo?.fullName}</span>
      </p>
      <p>
        Fuqaroligi: <span>{userInfo?.citizenship}</span>
      </p>
      <p>
        Jinsi:{" "}
        <span>
          {userInfo?.gender === "1"
            ? "Erkak"
            : userInfo?.gender === "2"
            ? "Ayol"
            : userInfo?.gender}
        </span>
      </p>
      {userPosition === "TEACHER" ? (
        ""
      ) : (
        <>
          <p>
            Millati: <span>{userInfo?.nationality}</span>
          </p>
          <p>
            Doimiy yashash joyi: <span>{userInfo?.permanentAddress}</span>
          </p>
        </>
      )} */}

      <br />
      <h2>Ta'lim ma'lumotlari</h2>
      {/* <p>
        Hemis Id: <span>{userInfo?.hemisId}</span>
      </p> */}
      <p>
        OTM: <span>{userInfo?.universityName}</span>
      </p>
      <p>
        Toifasi: <span>{user}</span>
      </p>
      {/* {userPosition === "TEACHER" ? (
        <>
          <p>
            Kafedrasi: <span>{userInfo?.cathedraName}</span>
          </p>
          <p>
            Ish shakli: <span>{userInfo?.employeeFormName}</span>
          </p>
          <p>
            Lavozimi: <span>{userInfo?.employeePositionName}</span>
          </p>
          <p>
            Ish stavkasi: <span>{userInfo?.employeeRateName}</span>
          </p>
          <p>
            Hozirgi holati: <span>{userInfo?.employeeStatusName}</span>
          </p>
        </>
      ) : (
        <>
          {" "}
          <p>
            Yo'nalishi: <span>{userInfo?.specialityName}</span>
          </p>
          <p>
            Kursi: <span>{userInfo?.course}</span>
          </p>
        </>
      )} */}
    </div>
  );
}

export default ProfileInfo;
