import { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import { MainContext } from "src/context";
import { useSearchParams } from "react-router-dom";
import { LanguageTypes } from "src/static/interface";
import { GetBlocksConfig } from "src/service/api";
import { rules } from "src/static/rules";

function BlockFilter() {
  const { handleMakeParams } = useContext(MainContext);
  const [searchParams] = useSearchParams();
  const blockId = searchParams.get("blockId");

  const { Option } = Select;

  const [blocks, setBlocks] = useState<LanguageTypes[]>([]);
  const [value, setValue] = useState<string | null>(null);

  const handleChange = (val: string) => {
    setValue(val);
    handleMakeParams?.("blockId", val);
    handleMakeParams?.("page", "1");
  };

  const getFunc = () => {
    GetBlocksConfig("?page=1&size=10000").then((res) => {
      setBlocks(res.data.content);
      blockId && setValue(blockId);
    });
  };

  useEffect(() => {
    getFunc();
  }, []);

  return (
    <Select
      value={value}
      allowClear
      placeholder={rules.block_s.message}
      showSearch
      onChange={handleChange}
      style={{ width: 350 }}
      optionFilterProp="children"
      filterOption={(input, option: any) =>
        option?.children?.toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        optionA.children
          .toLowerCase()
          .localeCompare(optionB.children.toLowerCase())
      }
    >
      {blocks.map((item) => (
        <Option key={item.id} value={String(item.id)}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
}

export default BlockFilter;
