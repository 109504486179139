import { useContext, useEffect } from "react";
import { MainContext } from "src/context";
import {
  Home,
  Profile,
  NotFound,
  SubjectTest,
  ProfileInfo,
  ProfileTests,
} from "src/pages";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { isUser, token } from "src/static/const";
import { blockDevTools } from "src/utils";
import TestPage from "src/pages/profile/tests/test";
import AdminPage from "src/pages/admin";
import Header from "src/components/header";
import Footer from "src/components/footer";
import TestAddPage from "src/pages/admin/test";
import CollectionPage from "src/pages/admin/collection";
import AdminLoginPage from "src/pages/admin/login";
import BlockPage from "src/pages/admin/block";
import QabulJsp from "src/pages/qabul.jsp";
import DeniedPage from "src/pages/denied";
import QuizCollectionPage from "src/pages/admin/quiz_collection";
import QuizCollectionActionsPage from "src/pages/admin/quiz_collection/actions";
import QuizPage from "src/pages/admin/quiz";
import QuizActionsPage from "src/pages/admin/quiz/actions";
import ThemePage from "src/pages/admin/theme";
import ThemeActionsPage from "src/pages/admin/theme/actions";
import TestActionsPage from "src/pages/admin/test/actions";
import CollectionActionsPage from "src/pages/admin/collection/actions";
import BlockActionsPage from "src/pages/admin/block/actions";
import QuizesPage from "src/pages/profile/quizes";
import QuizesSolvePage from "src/pages/profile/quizes/quiz";
import CollectionUsersPage from "src/pages/admin/collection/users";
import TeacherCollectionActionsPage from "src/pages/admin/collection/teacher_actions";

function RoutElements() {
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const [searchParams] = useSearchParams();
  const user = searchParams.get("user");
  const testId = searchParams.get("id");

  const { profileOpen, setProfileOpen } = useContext(MainContext);
  const handleClick = () => {
    if (profileOpen) setProfileOpen?.(false);
  };

  const checkFunc = () => {
    if (isUser) {
      //Check block dev tools
      if (user !== "tester") blockDevTools();
    }
  };

  useEffect(() => {
    checkFunc();
  }, [testId]);

  return (
    <div onClick={handleClick}>
      {path !== "admin" && <Header />}
      <div className="main-content">
        <Routes>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />
          {/* <Route path="/rating" element={<Rating />} /> */}
          <Route path="/qabul.jsp" element={<QabulJsp />} />
          <Route path="/denied" element={<DeniedPage />} />
          <Route path="/tests/solve" element={<TestPage />} />
          <Route path="/quizes/solve" element={<QuizesSolvePage />} />

          <Route
            path="/profile"
            element={token ? <Profile /> : <Navigate to="/login" />}
          >
            {/* <Route index element={<ProfileTests />} />
            <Route path="tests">
              <Route index element={<ProfileTests />} />
              <Route
                path="result"
                element={<SubjectTest location="profile" />}
              />
            </Route> */}
            <Route path="">
              <Route index element={<QuizesPage />} />
              <Route
                path="result"
                element={<QuizesSolvePage location="profile" />}
              />
            </Route>
             <Route path="quizes">
              <Route index element={<QuizesPage />} />
              <Route
                path="result"
                element={<QuizesSolvePage location="profile" />}
              />
            </Route>
            <Route path="informs" element={<ProfileInfo />} />
          </Route>

          <Route
            path="/admin"
            element={token ? <AdminPage /> : <AdminLoginPage />}
          >
            <Route index element={<CollectionPage />} />
            <Route
              path="collection/actions"
              element={<CollectionActionsPage />}
            />
            <Route path="collection/users" element={<CollectionUsersPage />} />

            <Route path="teacher" element={<CollectionPage user="teacher" />} />
            <Route
              path="teacher/actions"
              element={<TeacherCollectionActionsPage />}
            />
            <Route
              path="teacher/users"
              element={<CollectionUsersPage user="teacher" />}
            />

            <Route path="test" element={<TestAddPage />} />
            <Route path="test/actions" element={<TestActionsPage />} />

            <Route path="block" element={<BlockPage />} />
            <Route path="block/actions" element={<BlockActionsPage />} />

            <Route path="quiz_collection" element={<QuizCollectionPage />} />
            <Route
              path="quiz_collection/actions"
              element={<QuizCollectionActionsPage />}
            />

            <Route path="quiz" element={<QuizPage />} />
            <Route path="quiz/actions" element={<QuizActionsPage />} />

            <Route path="theme" element={<ThemePage />} />
            <Route path="theme/actions" element={<ThemeActionsPage />} />
          </Route>
        </Routes>
      </div>
      {path !== "admin" && <Footer />}
    </div>
  );
}

export default RoutElements;
