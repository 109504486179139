export const phoneMask = [
  { required: true, message: "Iltimos Telefon raqamni kiriting !" },
  {
    pattern: /^\(\d{2}\) \d{3} \d{2} \d{2}$/,
    message: "Iltimos raqamni (XX) XXX XX XX formatda kiriting !",
  },
];

export const passport_date = [
  {
    required: true,
    message: "Pasport berilgan sanani kiriting!",
  },
];

export const jshshr = [
  { required: true, message: "Iltimos JSHSHIR ni kiriting !" },
  {
    pattern: /^\d{14}$/,
    message: "JSHSHIR 14 raqamdan iborat !",
  },
];

export const appelatsiya = [
  {
    required: true,
    message: "Iltimos maydonni to'ldiring !",
  },
  {
    min: 10,
    message: "Kamida 10 ta har bo'lishi kerak",
  },
];

export const otm = [
  {
    required: true,
    message: "Iltimos OTM nomi tanlang !",
  },
];

export const yonalish = [
  {
    required: true,
    message: "Iltimos OTM yo'nalishini tanlang !",
  },
];

export const lang = [
  {
    required: true,
    message: "Iltimos yo'nalish tilini tanlang !",
  },
];

export const turi = [
  {
    required: true,
    message: "Iltimos yo'nalish shaklini tanlang !",
  },
];

export const rules = {
  login: { required: true, message: "Foydalanuvchi nomini kiriting" },
  password: { required: true, message: "Foydalanuvchi parolini kiriting" },
  code: { required: true, message: "Maxsus kodni kiriting" },
  hemis_login: { required: true, message: "Hemis loginni kiriting" },
  hemis_password: { required: true, message: "Hemis parolni kiriting" },
  hemis_pattern: {
    pattern: /^\d{3} \d{3} \d{3} \d{3}$/,
    message: "Iltimos loginni to'liq kiriting",
  },
  region: { required: true, message: "Viloyatni tanlang" },
  district: { required: true, message: "Tumanni tanlang" },
  phone: { required: true, message: "Telefon raqamni kiriting" },
  pinfl: { required: true, message: "JSHSHIR ni kiriting" },
  schools: { required: true, message: "Kasb-hunar maktabini tanlang" },
  direction_name: { required: true, message: "Yo'nalish nomini kiriting" },
  direction_code: { required: true, message: "Yo'nalish kodini kiriting" },
  categories: { required: true, message: "Test turini tanlang" },
  dificulty: { required: true, message: "Qiyinlik darajasini tanlang" },
  theme: { required: true, message: "Test mavzusini kiriting" },
  question: { required: true, message: "Test savolini kiriting" },
  university: { required: true, message: "Universitetni tanlang" },
  speciality: { required: true, message: "Mutaxasislikni tanlang" },
  faculty: { required: true, message: "Fakultetni tanlang" },
  file: { required: true, message: "Test faylini yuklang" },
  block: { required: true, message: "Blok nomini kiriting" },
  block_s: { required: true, message: "Blokni tanlang" },
  block_lan: { required: true, message: "Blok tilini tanlang" },
  time: { required: true, message: "Test uchun ajratilgan vaqtni kiriting" },
  level1: { required: true, message: "1-qiyinlikdagi testlar sonini kiriting" },
  level2: { required: true, message: "2-qiyinlikdagi testlar sonini kiriting" },
  level3: { required: true, message: "3-qiyinlikdagi testlar sonini kiriting" },
  theme_name: { required: true, message: "Mavzu nomini kiriting" },
  theme_lan: { required: true, message: "Mavzu tilini tanlang" },
  theme_s: { required: true, message: "Mavzuni tanlang" },
  quiz_type: { required: true, message: "So'rovnoma turini tanlang" },
  quiz_ans: { required: true, message: "So'rovnoma savolini kiriting" },
  quiz_v: { required: true, message: "So'rovnoma variantlarini kiriting" },
  quiz_end_t: { required: true, message: "So'rovnoma tugash vaqtini tanlang" },
};
